import DDQ from "./Orders";
import {Helmet} from "react-helmet-async";
import {
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    Grid,
    Link,
    Typography
} from "@mui/material";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import styled from "@emotion/styled";
import {spacing} from "@mui/system";
import {ExcelSheetSVG, MultipleFileSVG} from "../../components/Svgs";
import MultiFileProcessing from "../../components/tools/multi-file-processing";
import AddLogo from "./add_logo";


const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Tools = () => {
    const [currentTool, setCurrentTool] = useState<number>(0);
    const tools = [
        {
            id: 1,
            name: "PDF To Excel",
            icon: <ExcelSheetSVG />,
        },
        {
            id: 2,
            name: "Multiple File Processing",
            icon: <MultipleFileSVG />,
        },
    ];

    return (
        <React.Fragment>
            <Helmet title="Tools" />
            <Typography variant="h3" gutterBottom display="inline">
                Tools
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Typography>Tools</Typography>
            </Breadcrumbs>
            <Divider my={6} />
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card mb={6}>
                        <CardContent>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                    marginBottom: "60px",
                                    cursor: "pointer",
                                }}
                            >
                                {tools.map((tool) => {
                                    return (
                                        <div
                                            key={tool.id}
                                            onClick={() => {
                                                setCurrentTool(tool.id);
                                            }}
                                            style={{
                                                minWidth: "200px",
                                                height: "110px",
                                                fontSize: "1.1rem",
                                                border: "3px solid",
                                                marginRight: "20px",
                                                marginTop: "20px",
                                                backgroundColor: "#1B2430",
                                                color: "#fff",
                                                fontWeight: "bold",
                                                borderColor:
                                                    tool.id === currentTool ? "#C5585C" : "transparent",
                                                opacity: tool.id === currentTool ? "1" : "0.5",
                                                borderRadius: "5px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    padding: "6px 10px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        borderRadius: "50%",
                                                        fontWeight: "bold",
                                                        backgroundColor: "#eee",
                                                        color: "#333",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div style={{marginTop: '5px', width: "25px"}}>
                                                        {tool.icon}
                                                    </div>
                                                </div>
                                                <div style={{marginTop: "10px"}}>{tool.name}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>


                            {
                                currentTool === 1 && <DDQ/>
                            }

                            {
                                currentTool === 2 && <MultiFileProcessing />
                            }

                            {
                                currentTool === 3 && <AddLogo />
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Tools;
