import { CSSProperties } from "react";

export const styles: { [key: string]: CSSProperties } = {
  label: {
    fontWeight: "bold",
    padding: "10px", // Add padding for aesthetic spacing
    textAlign: "right", // Align the text to the right within the label cell
  },
  input: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "100%", // Make input take full width of its cell
  },
  defaultValue: {
    padding: "10px", // Add padding for aesthetic spacing
    marginLeft: "20px", // Space from the input cell
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "20px", // Space above the button
    marginBottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: "12px 24px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "24px",
    cursor: "pointer",
    height: "48px",
    fontWeight: "bold",
  },
  addbutton: {
    padding: "12px 24px",
    backgroundColor: "#00C04B",
    color: "#fff",
    border: "none",
    borderRadius: "24px",
    cursor: "pointer",
    height: "48px",
    fontWeight: "bold",
  },
};
