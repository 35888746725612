import { useState, useRef, useEffect } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import {
  Checkbox,
  TextField,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { toast } from "react-toastify";

const Divider = styled(MuiDivider)(spacing);

function DDQ() {
  const { user } = useAuth0();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [data, setData] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [summary, setSummary] = useState("");
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const checkBox = useRef<HTMLInputElement | null>(null);
  const checkBox2 = useRef<HTMLInputElement | null>(null);
  const resetData = () => {
    setData("");
    setFile(null);
    setSummary("");
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const getSummary = async (time: String) => {
    try {
      const response = await axiosInstance.get("/getSummary", {
        params: {
          timestamp: time,
        },
      });
      if (response.data === "error") {
        setData(
          "Unfortunately we cannot process encrypted files at this time. Please come back in a few weeks for the next release"
        );
      } else {
        await setSummary(response.data);
        setData("Email will be send to you shortly...");
      }
      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      console.error(err);
    }
  };
  const runscript = async () => {
    if (
      file &&
      userEmail !== "" &&
      (checkBox2.current?.checked || checkBox.current?.checked)
    ) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      setData("Preparing Summary... ");
      setTimeout(() => {
        setSummary("");
        getSummary(time);
      }, 5000);
      try {
        const formData = new FormData();
        const name = file.name.slice(0, -4);
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
        if (checkBox2.current?.checked || checkBox.current?.checked) {
          const response = await axiosInstance.post("/runscript", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            params: {
              timestamp: time,
              name: name,
              email: userEmail,
              checkBox: checkBox.current?.checked,
              checkBox2: checkBox2.current?.checked,
            },
          });
          if (response.data === "error") {
            toast.error(
              "Unfortunately there is un issue with the extraction. Please come back after sometimes for the service."
            );
          } else {
            toast.success("Email will be send to you shortly...");
          }

        } else {
          setSummary("Please select at least one option for the conversion");
        }
        // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error("No file selected.");
    }
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h3">PDF To Excel</Typography>
      <Divider my={6} />
      <div
        style={{
          width: "100%",
          display: "flex",
          // flexDirection: "column",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              textAlign: "justify",
            }}
          >
            Included is a functional demonstration of the PDF report data
            extraction feature under development by AltQ. We are committed to
            continuous enhancement and this will improve throughout Q1, 2024.
            You’re welcome to utilize this version while we finalize the
            comprehensive workflow.
          </Typography>
          <div
            style={{
              marginTop: "50px",
              border: "2px dashed gray",
              padding: "20px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onDragOver={(event) => event.preventDefault()}
            onDrop={onDrop}
            onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input
          >
            {file ? file.name : "Drag & Drop or Click to Select a File"}
            <input
              type="file"
              style={{ display: "none" }}
              onChange={onFileChange}
              onClick={(e: any) => (e.target.value = "")}
              ref={inputFileRef} // Attach the ref to the input
            />
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <TextField
              fullWidth
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              type="email"
              label="Email Address"
              variant="outlined"
              placeholder="email@example.com"
            />
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <label style={{ cursor: "pointer" }}>
              <Checkbox
                {...label}
                defaultChecked
                inputRef={checkBox}
                style={{ cursor: "pointer" }}
              />{" "}
              Standard Extract
            </label>
          </div>
          <div style={{ width: "100%" }}>
            <label style={{ cursor: "pointer" }}>
              <Checkbox
                {...label}
                inputRef={checkBox2}
                style={{ cursor: "pointer" }}
              />{" "}
              Deep Extract [ Use this if you are not satisfied with the Standard
              extraction ]
            </label>
          </div>
          Note: Selecting both options will generate 2 emails
          <button
            onClick={runscript}
            style={{
              marginLeft: "40%",
              marginTop: "20px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              padding: "12px 24px",
              borderRadius: "20px",
              fontSize: "16px",
              fontWeight: "500",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s, box-shadow 0.2s",
              cursor: "pointer",
              outline: "none",
            }}
          >
            Process
          </button>
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "10%",
            }}
          >
            <div style={{ paddingTop: "10px", marginRight: "4%" }}>{data}</div>
            {data ===
              "Unfortunately there is un issue with the extraction tool. Please come back after sometimes for the service " && (
              <button
                onClick={resetData}
                style={{
                  marginLeft: "40%",
                  marginTop: "20px",
                  backgroundColor: "#D32F2F",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "20px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                Reset
              </button>
            )}
          </div>
            {summary && (
                <>
                    <div
                        style={{
                            backgroundColor: "white",
                            border: "1px solid #e0e0e0",
                            padding: "15px",
                            textAlign: "justify",
                        }}
                    >
                        <b>Summary: </b>
                        {summary}
                    </div>
                    <button
                        onClick={resetData}
                        style={{
                            marginLeft: "40%",
                            marginTop: "20px",
                            backgroundColor: "#D32F2F",
                            color: "white",
                            border: "none",
                            padding: "12px 24px",
                            borderRadius: "20px",
                            fontSize: "16px",
                            fontWeight: "500",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.2s, box-shadow 0.2s",
                            cursor: "pointer",
                            outline: "none",
                        }}
                    >
                        Reset
                    </button>
                </>
            )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default DDQ;
