import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Card as MuiCard,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Select,
  Typography,
  Link,
} from "@mui/material";
import { spacing } from "@mui/system";
import FundStatic from "../../components/AddData/fundStatic";
import FundQtr from "../../components/AddData/fundQtr";
import PortfolioStatic from "../../components/AddData/portfolioStatic";
import PortfolioQtr from "../../components/AddData/portfolioQtr";
import axiosInstance from "../../utils/axios/axios.instance";
import { IFundStatic } from "../../interfaces/fundStatic.interface";
import { IPortfolioQtr } from "../../interfaces/portfolioQtr.interface";
import CapitalCall from "./workflow/capital_call/capital_call";
import { NavLink } from "react-router-dom";
import Distribution from "./workflow/distribution_call/distribution";
import PortfolioReview from "./workflow/Portfolio_review/portfolio_review";
import ExportCSVButton from "../../components/ExportCSVButton";
import {MultipleFileSVG} from "../../components/Svgs";
import AddLogo from "./add_logo";
import "../../Style/addData.css";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddData() {
  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };
  const getLatestQuarterAndYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    let quarter;
    if (currentMonth <= 3) {
      quarter = "03-31";
    } else if (currentMonth <= 6) {
      quarter = "06-30";
    } else if (currentMonth <= 9) {
      quarter = "09-30";
    } else {
      quarter = "12-31";
    }

    return { latestQuarter: quarter, latestYear: currentYear };
  };
  const [fundNames, setFundNames] = useState<Array<{ [key: string]: string }>>(
    []
  );
  const [portfolioNames, setPortfolioNames] = useState<
    Array<{ [key: string]: string }>
  >([]);
  const [selectedLayout, setSelectedLayout] = useState<string>("1");
  const [selectedFundID, setSelectedFundID] = useState("F000001");
  const [selectedPorfolioID, setSelectedPortfolioID] = useState("C000001");
  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names");
      const responseData = response.data.data;
      const fundNames = responseData.map((fund: IFundStatic) => {
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      setSelectedFundID(Object.values(fundNames[0])[0] as string);
      setFundNames(fundNames);
      if (fundID.current) {
        fundID.current.value = Object.values(fundNames[0])[0] as string;
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPortfolioNames = async () => {
    try {
      const response = await axiosInstance.get(
        "/getCompanies/" + fundID.current?.value
      );

      const responseData = response.data.data;
      const portfolioNames = responseData.map((portfolio: IPortfolioQtr) => {
        return {
          [portfolio.portfolio_companyName as string]: portfolio.company_ID,
        };
      });

      if (portfolioID.current) {
        portfolioID.current.value = Object.values(
          portfolioNames[0]
        )[0] as string;
        portfolioID.current.selectedIndex = 0;
      }
      setSelectedPortfolioID(Object.values(portfolioNames[0])[0] as string);
      setPortfolioNames(portfolioNames);
    } catch (error) {
      console.error(error);
    }
  };
  const [date, setDate] = React.useState("");
  const fundID = useRef<HTMLSelectElement>(null);
  const portfolioID = useRef<HTMLSelectElement>(null);


  const quarters = [
    { name: "Q1", value: "03-31" },
    { name: "Q2", value: "06-30" },
    { name: "Q3", value: "09-30" },
    { name: "Q4", value: "12-31" },
  ];
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const handleQuarterChange = (event: any) => {
    setSelectedQuarter(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    if (selectedQuarter && selectedYear) {
      const dateParts = selectedQuarter.split("-");
      const formattedDate = `${dateParts[1]}-${dateParts[0]}-${selectedYear}`;
      setDate(formattedDate);
    }
  }, [selectedQuarter, selectedYear]);
  useEffect(() => {
    document.title = "Add Data";
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    getFundNames();
    // getPortfolioNames();

    const { latestQuarter, latestYear } = getLatestQuarterAndYear();
    setSelectedQuarter(latestQuarter);
    setSelectedYear(latestYear.toString());
  }, []);

  const funds = [
    {
      id: "1",
      name: "Fund Static",
      type: "static",
    },
    {
      id: "2",
      name: "Fund Qtr",
      type: "dynamic",
    },
    {
      id: "3",
      name: "Portfolio Static",
      type: "static",
    },
    {
      id: "4",
      name: "Portfolio Qtr",
      type: "dynamic",
    },
    {
      id: "5",
      name: "Upload Quarterly Reports",
      type: "dynamic",
    },
    {
      id: "6",
      name: "Capital Call",
      type: "dynamic",
    },
    {
      id: "7",
      name: "Distribution",
      type: "dynamic",
    },
  ];

  const [currentFund, setCurrentFund] = useState("1");

  const PeriodSelector = () => {
    return (
      <div style={{ display: "flex", marginTop: "20px" }}>
        <div style={{ paddingLeft: "20px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <FormControl
                style={{
                  width: "110px",
                }}
                // fullWidth
              >
                <InputLabel>Quarter</InputLabel>
                <Select
                  value={selectedQuarter}
                  onChange={handleQuarterChange}
                  label="Quarter"
                >
                  {quarters.map((quarter) => (
                    <MenuItem key={quarter.name} value={quarter.value}>
                      {quarter.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Year Selector */}
            <Grid item xs={12} sm={6}>
              <FormControl
                style={{
                  width: "110px",
                }}
              >
                <InputLabel>Year</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust max height to your preference
                      },
                    },
                  }}
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* <DatePicker onChange={changeDate}></DatePicker> */}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Add/Update Data" />
      <Typography variant="h3" gutterBottom display="inline">
        Add New Data
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Add Update data</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div
                  id="add-data-grid"
              >
                {funds.map((fund, index) => {
                  return (
                    <div
                      key={fund.id}
                      onClick={() => {
                        setCurrentFund(fund.id);
                        setSelectedLayout(fund.id);
                        if (fund.id === "3" || fund.id === "4") {
                          getPortfolioNames();
                        }
                      }}
                      style={{
                        minWidth: "120px",
                        maxWidth: fund.name === "Upload Quarterly Reports" ? "250px" : "250px",
                        height: "90px",
                        fontSize: "1.1rem",
                        border: "3px solid",
                        marginLeft: "20px",
                        marginTop: "20px",
                        backgroundColor: "#1B2430",
                        color: "#fff",
                        fontWeight: "bold",
                        borderColor:
                          fund.id === currentFund ? "#C5585C" : "transparent",
                        opacity: fund.id === currentFund ? "1" : "0.5",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: "6px 10px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "0.5rem",
                            border: "1px solid #ddd",
                            padding: "2px 5px",
                            borderRadius: "30px",
                            width: "fit-content",
                            fontWeight: "bold",
                            backgroundColor: "#eee",
                            color: "#333",
                          }}
                        >
                          {fund.type}
                        </div>
                        <div className="add-card-name" style={{ marginTop: "10px" }}>{fund.name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {selectedLayout === "1" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <h1>Fund Static</h1>
                    <ExportCSVButton collection="fundStatic"/>
                  </div>
                  <div style={{marginRight: "20px"}}>Fund ID:</div>
                  <select
                    ref={fundID}
                    onChange={() => {
                      setSelectedFundID(fundID.current?.value ?? "");
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                      height: "52px",
                      marginTop: "20px",
                    }}
                  >
                    {fundNames.map((fund, index) => {
                      const fundName = Object.keys(fund)[0];
                      const fundID = fund[fundName];
                      return (
                        <option key={index} value={fundID}>
                          {fundName}
                        </option>
                      );
                    })}
                  </select>

                  <div style={{ marginTop: "20px" }}>
                    <FundStatic id={selectedFundID} />
                  </div>
                </div>
              )}

              {selectedLayout === "2" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <h1>Fund Qtr</h1>
                    <ExportCSVButton collection="fundQtr"/>
                  </div>
                  <div style={{marginRight: "20px"}}>Fund ID:</div>

                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <select
                      ref={fundID}
                      onChange={() => {
                        setSelectedFundID(fundID.current?.value ?? "");
                      }}
                      style={{
                        backgroundColor: "#f2f2f2",
                        color: "#333",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        outline: "none",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                        width: "200px",
                        cursor: "pointer",
                        height: "52px",
                      }}
                    >
                      {fundNames.map((fund, index) => {
                        const fundName = Object.keys(fund)[0];
                        const fundID = fund[fundName];
                        return (
                          <option key={index} value={fundID}>
                            {fundName}
                          </option>
                        );
                      })}
                    </select>

                    <PeriodSelector />
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <FundQtr date={date} id={selectedFundID} />
                  </div>
                </div>
              )}

              {selectedLayout === "3" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <h1>Portfolio Static</h1>
                    <ExportCSVButton collection="portfolioStatic"/>
                  </div>
                  <div style={{marginRight: "20px"}}>Portfolio Name:</div>
                  <select
                    ref={fundID}
                    onChange={() => {
                      setSelectedFundID(fundID.current?.value ?? "");
                      getPortfolioNames();
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                      height: "52px",
                      marginTop: "20px",
                    }}
                  >
                    {fundNames.map((fund, index) => {
                      const fundName = Object.keys(fund)[0];
                      const fundID = fund[fundName];
                      return (
                        <option key={index} value={fundID}>
                          {fundName}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    ref={portfolioID}
                    onChange={() => {
                      setSelectedPortfolioID(portfolioID.current?.value ?? "");
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                      marginLeft: "40px",
                      height: "52px",
                    }}
                  >
                    {portfolioNames.map((portfolio, index) => {
                      const portfolioName = Object.keys(portfolio)[0];
                      const company_ID = portfolio[portfolioName];
                      return (
                        <option key={index} value={company_ID}>
                          {portfolioName}
                        </option>
                      );
                    })}
                  </select>

                  <div style={{ marginTop: "20px" }}>
                    <PortfolioStatic id={selectedPorfolioID} />
                  </div>
                </div>
              )}

              {selectedLayout === "4" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <h1>Portfolio Qtr</h1>
                    <ExportCSVButton collection="portfolioQtr"/>
                  </div>
                  <div style={{marginRight: "20px"}}>Portfolio Name:</div>

                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <select
                      ref={fundID}
                      onChange={() => {
                        setSelectedFundID(fundID.current?.value ?? "");
                        getPortfolioNames();
                      }}
                      style={{
                        backgroundColor: "#f2f2f2", // Background color
                        color: "#333", // Text color
                        padding: "10px", // Padding
                        border: "1px solid #ccc", // Border
                        borderRadius: "5px", // Border radius
                        outline: "none", // Remove outline when selected
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                        width: "200px", // Width
                        cursor: "pointer", // Cursor style
                        height: "52px",
                      }}
                    >
                      {fundNames.map((fund, index) => {
                        const fundName = Object.keys(fund)[0];
                        const fundID = fund[fundName];
                        return (
                          <option key={index} value={fundID}>
                            {fundName}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      ref={portfolioID}
                      onChange={() => {
                        setSelectedPortfolioID(
                          portfolioID.current?.value ?? ""
                        );
                      }}
                      style={{
                        backgroundColor: "#f2f2f2", // Background color
                        color: "#333", // Text color
                        padding: "10px", // Padding
                        border: "1px solid #ccc", // Border
                        borderRadius: "5px", // Border radius
                        outline: "none", // Remove outline when selected
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                        width: "200px", // Width
                        cursor: "pointer", // Cursor style
                        marginLeft: "20px",
                        height: "52px",
                      }}
                    >
                      {portfolioNames.map((portfolio, index) => {
                        const portfolioName = Object.keys(portfolio)[0];
                        const company_ID = portfolio[portfolioName];
                        return (
                          <option key={index} value={company_ID}>
                            {portfolioName}
                          </option>
                        );
                      })}
                    </select>

                    <PeriodSelector />
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <PortfolioQtr data={date} id={selectedPorfolioID} fund_ID={selectedFundID} />
                  </div>
                </div>
              )}

              {selectedLayout === "5" && (
                  <div style={{ paddingTop: "20px" }}>
                    <div>
                      <AddLogo />
                    </div>
                  </div>
              )}

              {selectedLayout === "6" && (
                <div style={{ paddingTop: "20px" }}>
                  <div>
                    <CapitalCall />
                  </div>
                </div>
              )}

              {selectedLayout === "7" && (
                <div style={{ paddingTop: "20px" }}>
                  <div>
                    <Distribution />
                  </div>
                </div>
              )}

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddData;
