export interface FOFReports {
  _id: string;
  Reporting_Date: string;
  lp_name: string;
  lp_vintage: number;
  lp_aggregateCommitments: number;
  lp_recallableDistributions: number;
  lp_advances: number;
  lp_total: number;
  lp_called: number;
  lp_uncalled: number;
  lp_commitmentPortfolioInvt: number;
  lp_distrobutionsToLP: number;
  lp_navToLP: number;
  lp_totalValue: number;
  lp_navGP: number;
  lp_totalNAV: number;
  lp_fundGrossIRR: number;
  lp_fundNetIRR: number;
  lp_fundNetIRR_A: number;
  lp_fundNetIRR_B: number;
  lp_navIQ: number;
  lp_navCDPQ: number;
  lp_navIFSTQ: number;
  lp_definitiveDistributions: number;
  lp_dq_calls: number;
  lp_dq_distributionsPaid: number;
  lp_dq_drewCredit: number;
  lp_dq_repayCredit: number;
  lp_eq_calls: number;
  lp_eq_distributionsPaid: number;
  lp_eq_drewCredit: number;
  lp_aq_calls: number;
  lp_aq_distributionsPaid: number;
  lp_aq_drewCredit: number;
  lp_aq_repayCredit: number;
  FoFkey: string;
}
export const fieldNames: { [key in keyof FOFReports]: string } = {
  _id: "ID",
  Reporting_Date: "Reporting Date",
  lp_name: "Name",
  lp_vintage: "Vintage",
  lp_aggregateCommitments: "Aggregate Commitments",
  lp_recallableDistributions: "Recallable Distributions",
  lp_advances: "Advances",
  lp_total: "Total",
  lp_called: "Called",
  lp_uncalled: "Uncalled",
  lp_commitmentPortfolioInvt: "Commitment Portfolio Invt",
  lp_distrobutionsToLP: "Distrobutions To LP",
  lp_navToLP: "NAV To LP",
  lp_totalValue: "Total Value",
  lp_navGP: "NAV GP",
  lp_totalNAV: "Total NAV",
  lp_fundGrossIRR: "Fund Gross IRR",
  lp_fundNetIRR: "Fund Net IRR",
  lp_fundNetIRR_A: "Fund Net IRR A",
  lp_fundNetIRR_B: "Fund Net IRR B",
  lp_navIQ: "NAV IQ",
  lp_navCDPQ: "NAV CDPQ",
  lp_navIFSTQ: "NAV IFSTQ",
  lp_definitiveDistributions: "Definitive Distributions",
  lp_dq_calls: "DQ Calls",
  lp_dq_distributionsPaid: "DQ Distributions Paid",
  lp_dq_drewCredit: "DQ Drew Credit",
  lp_dq_repayCredit: "DQ Repay Credit",
  lp_eq_calls: "EQ Calls",
  lp_eq_distributionsPaid: "EQ Distributions Paid",
  lp_eq_drewCredit: "EQ Drew Credit",
  lp_aq_calls: "AQ Calls",
  lp_aq_distributionsPaid: "AQ Distributions Paid",
  lp_aq_drewCredit: "AQ Drew Credit",
  lp_aq_repayCredit: "AQ Repay Credit",
  FoFkey: "FoF Key",
};

export const orderArray = [
  "_id",
  "Reporting_Date",
  "lp_name",
  "lp_vintage",
  "lp_aggregateCommitments",
  "lp_recallableDistributions",
  "lp_advances",
  "lp_total",
  "lp_called",
  "lp_uncalled",
  "lp_commitmentPortfolioInvt",
  "lp_distrobutionsToLP",
  "lp_navToLP",
  "lp_totalValue",
  "lp_navGP",
  "lp_totalNAV",
  "lp_fundGrossIRR",
  "lp_fundNetIRR",
  "lp_fundNetIRR_A",
  "lp_fundNetIRR_B",
  "lp_navIQ",
  "lp_navCDPQ",
  "lp_navIFSTQ",
  "lp_definitiveDistributions",
  "lp_dq_calls",
  "lp_dq_distributionsPaid",
  "lp_dq_drewCredit",
  "lp_dq_repayCredit",
  "lp_eq_calls",
  "lp_eq_distributionsPaid",
  "lp_eq_drewCredit",
  "lp_aq_calls",
  "lp_aq_distributionsPaid",
  "lp_aq_drewCredit",
  "lp_aq_repayCredit",
  "FoFkey",
];
