import { CSSProperties, useState } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import {
  IPortfolioQtr,
  PortfolioQtrProps,
} from "../../interfaces/portfolioQtr.interface";
import DynamicForm from "../../pages/pages/Add_updateForm";
import { toast } from "react-toastify";

function PortfolioQtr({ data, id, fund_ID }: PortfolioQtrProps) {
  const [fieldData, setFieldData] = useState({});
  const [portfolioData, setPortfolioData] = useState<any>({});
  const [updateMessage, setUpdateMessage] = useState("");
  const [newData, setNewData] = useState(false);
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "right", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%", // Make input take full width of its cell
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "24px",
      cursor: "pointer",
      height: "48px",
      fontWeight: "bold",
    },
    addbutton: {
      padding: "12px 24px",
      backgroundColor: "#00C04B",
      color: "#fff",
      border: "none",
      borderRadius: "24px",
      cursor: "pointer",
      height: "48px",
      fontWeight: "bold",
    },
  };
  function createFieldData(responseData: IPortfolioQtr): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | Number;
    };
  } {
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | Number;
      };
    } = {};

    Object.keys(responseData).forEach((key) => {
      if (
        key === "_id" ||
        key === "ReportingDateKey" ||
        key === "ReportingDateString"
      ) {
        // Exclude specific fields from being added to fieldData
      } else {
        if (key === "Reporting_Date") {
          // Check if the field name is the date field you want to set as "type: date"
          fieldData[key] = {
            type: "date",
            defaultValue: responseData[key as keyof IPortfolioQtr] as Date,
          };
        } else {
          const value = responseData[key as keyof IPortfolioQtr];
          if (typeof value === "number") {
            fieldData[key] = {
              type: "number",
              defaultValue: value,
            };
          } else {
            fieldData[key] = {
              type: "text",
              defaultValue: value as string | undefined,
            };
          }
        }
      }
    });
    return fieldData;
  }
  const fetchData2 = async () => {
    setFieldData({});
    setNewData(true);
    setUpdateMessage("update");
    try {
      const validation = await axiosInstance.get(
        "/getPortfolioQtr/" + data + "/" + id + "/" + fund_ID
      );
      if (validation.data.data.length !== 0) {
        toast.error("Data already exists for the given date please update it");
        return;
      }
      const response = await axiosInstance.get(
        "/portfolio-qtr-sample/" + data + "/" + id + "/" + fund_ID
      );
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          toast.error("There is no data with the given Date");
          setFieldData({});
        } else {
          const responseData = response.data.data[0]; // Assuming this is your response data
          setFieldData(createFieldData(responseData));
        }

        // Handle success
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };
  // Define an async function inside useEffect
  const fetchData = async () => {
    setUpdateMessage("add");
    if (data == null || data === "" || id == null || id === "") {
      toast.error("Please select a fund ID and a date");
      return;
    } else {
      setFieldData({});
      setNewData(false);
      try {
        const response = await axiosInstance.get(
          "/getPortfolioQtr/" + data + "/" + id + "/" + fund_ID
        );
        if (response.data === "error") {
          toast.error("Error while fetching Data.");
        } else {
          if (response.data.data.length === 0) {
            toast.error("There is no data with the given Date");
            setFieldData({});
          } else {
            const responseData = response.data.data[0]; // Assuming this is your response data
            setFieldData(createFieldData(responseData));
            setPortfolioData(responseData);
          }

          // Handle success
        }
      } catch (err) {
        toast.error("Error while fetching Data.");
        console.error(err);
      }
    }
  };
  async function handleChangeData(
    formData: Record<string, string>,
    updateType: string
  ) {
    if (updateType !== "add" && updateType !== "update") {
      toast.error("Invalid update type");
      return;
    }
    try {
      let response;
      if (updateType === "add") {
        formData.fund_ID = fund_ID;
        formData.company_ID = id;
        formData.Reporting_Date = data;

        response = await axiosInstance.post("/add_portfolioQtr", { formData });
      } else {
        const updatedFormData = { ...formData };
        let uploadQtrRespData = { ...portfolioData };
        Object.keys(updatedFormData).forEach((key) => {
          // eslint-disable-next-line eqeqeq
          if (
            key === "Reporting_Date" ||
            key === "fund_ID" ||
            key === "company_ID" ||
            key === "ReportingDateKey"
          )
            return;
          uploadQtrRespData[key] = updatedFormData[key as keyof IPortfolioQtr];
        });
        response = await axiosInstance.patch("/update_portfolioQtr", {
          company_ID: id,
          Reporting_Date: data,
          formData: uploadQtrRespData,
          fund_ID: fund_ID,
        });
      }

      if (response.data === "error") {
        toast.error("Error occurred while processing data");
      } else {
        toast.success(
          `Data successfully ${updateType === "add" ? "added" : "updated"}`
        );

        setFieldData({});
      }
    } catch (err) {
      console.error(err);
      toast.error("Error occurred while processing data");
    }
  }
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.button} onClick={fetchData}>
            Get previous data
          </button>
        </div>
        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.addbutton} onClick={fetchData2}>
            Add new Data
          </button>
        </div>
      </div>
      {Object.keys(fieldData).length !== 0 && (
        <>
          {updateMessage === "update" ? (
            <DynamicForm
              fieldData={fieldData}
              changedata={handleChangeData}
              staticData={false}
              newData={newData}
              reportingDate={data}
            />
          ) : (
            <DynamicForm
              fieldData={fieldData}
              changedata={handleChangeData}
              staticData={true}
              newData={newData}
              reportingDate={data}
            />
          )}
        </>
      )}
    </div>
  );
}
export default PortfolioQtr;
