export interface PortfolioReviewReport {
  Reporting_Date: string;
  formKey: string;
  portfolio_companyName: string;
  Textboxes_Valuation: string;
  LastValChange_QQ: string;
  LastValChange_YYYY: string;
  LastValChange_Nature: string;
  InvtHistory_Currency_1: string;
  InvtHistory_Date_1: string;
  InvtHistory_Series_1: string;
  InvtHistory_InvGP_1: number;
  InvtHistory_RoundSize_1: number;
  InvtHistory_PostMoney_1: number;
  InvtHistory_Currency_2: string;
  InvtHistory_Date_2: string;
  InvtHistory_Series_2: string;
  InvtHistory_InvGP_2: number;
  InvtHistory_RoundSize_2: number;
  InvtHistory_PostMoney_2: number;
  InvtHistory_Currency_3: string;
  InvtHistory_Date_3: string;
  InvtHistory_Series_3: string;
  InvtHistory_InvGP_3: number;
  InvtHistory_RoundSize_3: number;
  InvtHistory_PostMoney_3: number;
  InvtHistory_Currency_4: string;
  InvtHistory_Date_4: string;
  InvtHistory_Series_4: string;
  InvtHistory_InvGP_4: number;
  InvtHistory_RoundSize_4: number;
  InvtHistory_PostMoney_4: number;
  InvtHistory_Currency_5: string;
  InvtHistory_Date_5: string;
  InvtHistory_Series_5: string;
  InvtHistory_InvGP_5: number;
  InvtHistory_RoundSize_5: number;
  InvtHistory_PostMoney_5: number;
  InvtHistory_Currency_6: string;
  InvtHistory_Date_6: string;
  InvtHistory_Series_6: string;
  InvtHistory_InvGP_6: number;
  InvtHistory_RoundSize_6: number;
  InvtHistory_PostMoney_6: number;
  InvtHistory_Currency_7: string;
  InvtHistory_Date_7: string;
  InvtHistory_Series_7: string;
  InvtHistory_InvGP_7: number;
  InvtHistory_RoundSize_7: number;
  InvtHistory_PostMoney_7: number;
  InvtHistory_Currency_8: string;
  InvtHistory_Date_8: string;
  InvtHistory_Series_8: string;
  InvtHistory_InvGP_8: number;
  InvtHistory_RoundSize_8: number;
  InvtHistory_PostMoney_8: number;
  InvtHistory_Currency_9: string;
  InvtHistory_Date_9: string;
  InvtHistory_Series_9: string;
  InvtHistory_InvGP_9: number;
  InvtHistory_RoundSize_9: number;
  InvtHistory_PostMoney_9: number;
  Textboxes_Overview: string;
  Textboxes_Overview_BusinessModel: string;
  Textboxes_Overview_InvestmentThesis: string;
  Textboxes_Overview_Management: string;
  Textboxes_Overview_Shareholders: string;
  Textboxes_Overview_Influence: string;
  BS_Runway: string;
  Valuation_Multiple: number;
  EmpCnt_CurrentFYE: number;
  EmpCnt_NextFYE: number;
  EmpCnt_CurrentFY: number;
  EmpCnt_LastFY: number;
  EmpCnt_LastFY01: number;
  EmpCnt_LastFY02: number;
  EmpCnt_LastFY03: number;
  EmpCnt_Initial: number;
  TopLine_CurrentFY_YTD: number;
  TopLine_CurrentFYE: number;
  TopLine_NextFYE: number;
  TopLine_LastFY: number;
  TopLine_LastFY01: number;
  TopLine_LastFY02: number;
  TopLine_LastFY03: number;
  TopLine_Initial: number;
  Profitability_CurrentFY0YTD: number;
  Profitability_CurrentFYE: number;
  Profitability_NextFYE: number;
  Profitability_LastFY: number;
  Profitability_LastFY01: number;
  Profitability_LastFY02: number;
  Profitability_LastFY03: number;
  Profitability_Initial: number;
  EqVal_Current: number;
  EqVal_Current_FYE: number;
  EqVal_Next_FYE: number;
  EqVal_LastFY: number;
  EqVal_LastFY01: number;
  EqVal_LastFY02: number;
  EqVal_LastFY03: number;
  EqVal_Initial: number;
  NetDebtCash_Current_FY_YTD: number;
  NetDebtCash_Current_FYE: number;
  NetDebtCash_Next_FYE: number;
  NetDebtCash_LastFY: number;
  NetDebtCash_LastFY_1: number;
  NetDebtCash_LastFY_2: number;
  NetDebtCash_LastFY_3: number;
  NetDebtCash_Initial: number;
  Textboxes_QtrUpdate: string;
  Textboxes_Milestones_ST: string;
  Textboxes_Outlook_LT: string;
  EntValue_Current: number;
  EntValue_LastFY: number;
  EntValue_LastFY01: number;
  EntValue_LastFY02: number;
  EntValue_LastFY03: number;
  EntValue_Initial: number;
  Exp_Final_Return_MOIC: string;
  Exp_Final_Return_Tier: string;
  Exp_Final_Return_delta: string;
  Exp_Final_Return_Final: string;
  Exp_Final_Return_Excess_Shortfall: string;
  Exp_Final_Return_TC_Rating: string;
  company_ID: string;
}
export const fieldNames: { [key in keyof PortfolioReviewReport]: string } = {
  Reporting_Date: "Reporting Date",
  formKey: "Form Key",
  portfolio_companyName: "Portfolio Company Name",
  Textboxes_Valuation: "Textboxes Valuation",
  LastValChange_QQ: "Last Valuation Change QQ",
  LastValChange_YYYY: "Last Valuation Change YYYY",
  LastValChange_Nature: "Last Valuation Change Nature",
  InvtHistory_Currency_1: "Investment History Currency 1",
  InvtHistory_Date_1: "Investment History Date 1",
  InvtHistory_Series_1: "Investment History Series 1",
  InvtHistory_InvGP_1: "Investment History InvGP 1",
  InvtHistory_RoundSize_1: "Investment History RoundSize 1",
  InvtHistory_PostMoney_1: "Investment History PostMoney 1",
  InvtHistory_Currency_2: "Investment History Currency 2",
  InvtHistory_Date_2: "Investment History Date 2",
  InvtHistory_Series_2: "Investment History Series 2",
  InvtHistory_InvGP_2: "Investment History InvGP 2",
  InvtHistory_RoundSize_2: "Investment History RoundSize 2",
  InvtHistory_PostMoney_2: "Investment History PostMoney 2",
  InvtHistory_Currency_3: "Investment History Currency 3",
  InvtHistory_Date_3: "Investment History Date 3",
  InvtHistory_Series_3: "Investment History Series 3",
  InvtHistory_InvGP_3: "Investment History InvGP 3",
  InvtHistory_RoundSize_3: "Investment History RoundSize 3",
  InvtHistory_PostMoney_3: "Investment History PostMoney 3",
  InvtHistory_Currency_4: "Investment History Currency 4",
  InvtHistory_Date_4: "Investment History Date 4",
  InvtHistory_Series_4: "Investment History Series 4",
  InvtHistory_InvGP_4: "Investment History InvGP 4",
  InvtHistory_RoundSize_4: "Investment History RoundSize 4",
  InvtHistory_PostMoney_4: "Investment History PostMoney 4",
  InvtHistory_Currency_5: "Investment History Currency 5",
  InvtHistory_Date_5: "Investment History Date 5",
  InvtHistory_Series_5: "Investment History Series 5",
  InvtHistory_InvGP_5: "Investment History InvGP 5",
  InvtHistory_RoundSize_5: "Investment History RoundSize 5",
  InvtHistory_PostMoney_5: "Investment History PostMoney 5",
  InvtHistory_Currency_6: "Investment History Currency 6",
  InvtHistory_Date_6: "Investment History Date 6",
  InvtHistory_Series_6: "Investment History Series 6",
  InvtHistory_InvGP_6: "Investment History InvGP 6",
  InvtHistory_RoundSize_6: "Investment History RoundSize 6",
  InvtHistory_PostMoney_6: "Investment History PostMoney 6",
  InvtHistory_Currency_7: "Investment History Currency 7",
  InvtHistory_Date_7: "Investment History Date 7",
  InvtHistory_Series_7: "Investment History Series 7",
  InvtHistory_InvGP_7: "Investment History InvGP 7",
  InvtHistory_RoundSize_7: "Investment History RoundSize 7",
  InvtHistory_PostMoney_7: "Investment History PostMoney 7",
  InvtHistory_Currency_8: "Investment History Currency 8",
  InvtHistory_Date_8: "Investment History Date 8",
  InvtHistory_Series_8: "Investment History Series 8",
  InvtHistory_InvGP_8: "Investment History InvGP 8",
  InvtHistory_RoundSize_8: "Investment History RoundSize 8",
  InvtHistory_PostMoney_8: "Investment History PostMoney 8",
  InvtHistory_Currency_9: "Investment History Currency 9",
  InvtHistory_Date_9: "Investment History Date 9",
  InvtHistory_Series_9: "Investment History Series 9",
  InvtHistory_InvGP_9: "Investment History InvGP 9",
  InvtHistory_RoundSize_9: "Investment History RoundSize 9",
  InvtHistory_PostMoney_9: "Investment History PostMoney 9",
  Textboxes_Overview: "Textboxes Overview",
  Textboxes_Overview_BusinessModel: "Textboxes Overview Business Model",
  Textboxes_Overview_InvestmentThesis: "Textboxes Overview Investment Thesis",
  Textboxes_Overview_Management: "Textboxes Overview Management",
  Textboxes_Overview_Shareholders: "Textboxes Overview Shareholders",
  Textboxes_Overview_Influence: "Textboxes Overview Influence",
  BS_Runway: "BS Runway",
  Valuation_Multiple: "Valuation Multiple",
  EmpCnt_CurrentFYE: "Employee Count Current FYE",
  EmpCnt_NextFYE: "Employee Count Next FYE",
  EmpCnt_CurrentFY: "Employee Count Current FY",
  EmpCnt_LastFY: "Employee Count Last FY",
  EmpCnt_LastFY01: "Employee Count Last FY -1",
  EmpCnt_LastFY02: "Employee Count Last FY -2",
  EmpCnt_LastFY03: "Employee Count Last FY -3",
  EmpCnt_Initial: "Employee Count Initial",
  TopLine_CurrentFY_YTD: "Top Line Current FY YTD",
  TopLine_CurrentFYE: "Top Line Current FYE",
  TopLine_NextFYE: "Top Line Next FYE",
  TopLine_LastFY: "Top Line Last FY",
  TopLine_LastFY01: "Top Line Last FY -1",
  TopLine_LastFY02: "Top Line Last FY -2",
  TopLine_LastFY03: "Top Line Last FY -3",
  TopLine_Initial: "Top Line Initial",
  Profitability_CurrentFY0YTD: "Profitability Current FY YTD",
  Profitability_CurrentFYE: "Profitability Current FYE",
  Profitability_NextFYE: "Profitability Next FYE",
  Profitability_LastFY: "Profitability Last FY",
  Profitability_LastFY01: "Profitability Last FY -1",
  Profitability_LastFY02: "Profitability Last FY -2",
  Profitability_LastFY03: "Profitability Last FY -3",
  Profitability_Initial: "Profitability Initial",
  EqVal_Current: "Equity Value Current",
  EqVal_Current_FYE: "Equity Value Current FYE",
  EqVal_Next_FYE: "Equity Value Next FYE",
  EqVal_LastFY: "Equity Value Last FY",
  EqVal_LastFY01: "Equity Value Last FY -1",
  EqVal_LastFY02: "Equity Value Last FY -2",
  EqVal_LastFY03: "Equity Value Last FY -3",
  EqVal_Initial: "Equity Value Initial",
  NetDebtCash_Current_FY_YTD: "Net Debt Cash Current FY YTD",
  NetDebtCash_Current_FYE: "Net Debt Cash Current FYE",
  NetDebtCash_Next_FYE: "Net Debt Cash Next FYE",
  NetDebtCash_LastFY: "Net Debt Cash Last FY",
  NetDebtCash_LastFY_1: "Net Debt Cash Last FY -1",
  NetDebtCash_LastFY_2: "Net Debt Cash Last FY -2",
  NetDebtCash_LastFY_3: "Net Debt Cash Last FY -3",
  NetDebtCash_Initial: "Net Debt Cash Initial",
  Textboxes_QtrUpdate: "Textboxes Quarterly Update",
  Textboxes_Milestones_ST: "Textboxes Milestones Short Term",
  Textboxes_Outlook_LT: "Textboxes Outlook Long Term",
  EntValue_Current: "Enterprise Value Current",
  EntValue_LastFY: "Enterprise Value Last FY",
  EntValue_LastFY01: "Enterprise Value Last FY -1",
  EntValue_LastFY02: "Enterprise Value Last FY -2",
  EntValue_LastFY03: "Enterprise Value Last FY -3",
  EntValue_Initial: "Enterprise Value Initial",
  Exp_Final_Return_MOIC: "Expected Final Return MOIC",
  Exp_Final_Return_Tier: "Expected Final Return Tier",
  Exp_Final_Return_delta: "Expected Final Return Delta",
  Exp_Final_Return_Final: "Expected Final Return Final",
  Exp_Final_Return_Excess_Shortfall: "Expected Final Return Excess Shortfall",
  Exp_Final_Return_TC_Rating: "Expected Final Return TC Rating",
  company_ID: "Company ID",
};

export const orderArray: (keyof PortfolioReviewReport)[] = [
  "Reporting_Date",
  "formKey",
  "portfolio_companyName",
  "Textboxes_Valuation",
  "LastValChange_QQ",
  "LastValChange_YYYY",
  "LastValChange_Nature",
  "InvtHistory_Currency_1",
  "InvtHistory_Date_1",
  "InvtHistory_Series_1",
  "InvtHistory_InvGP_1",
  "InvtHistory_RoundSize_1",
  "InvtHistory_PostMoney_1",
  "InvtHistory_Currency_2",
  "InvtHistory_Date_2",
  "InvtHistory_Series_2",
  "InvtHistory_InvGP_2",
  "InvtHistory_RoundSize_2",
  "InvtHistory_PostMoney_2",
  "InvtHistory_Currency_3",
  "InvtHistory_Date_3",
  "InvtHistory_Series_3",
  "InvtHistory_InvGP_3",
  "InvtHistory_RoundSize_3",
  "InvtHistory_PostMoney_3",
  "InvtHistory_Currency_4",
  "InvtHistory_Date_4",
  "InvtHistory_Series_4",
  "InvtHistory_InvGP_4",
  "InvtHistory_RoundSize_4",
  "InvtHistory_PostMoney_4",
  "InvtHistory_Currency_5",
  "InvtHistory_Date_5",
  "InvtHistory_Series_5",
  "InvtHistory_InvGP_5",
  "InvtHistory_RoundSize_5",
  "InvtHistory_PostMoney_5",
  "InvtHistory_Currency_6",
  "InvtHistory_Date_6",
  "InvtHistory_Series_6",
  "InvtHistory_InvGP_6",
  "InvtHistory_RoundSize_6",
  "InvtHistory_PostMoney_6",
  "InvtHistory_Currency_7",
  "InvtHistory_Date_7",
  "InvtHistory_Series_7",
  "InvtHistory_InvGP_7",
  "InvtHistory_RoundSize_7",
  "InvtHistory_PostMoney_7",
  "InvtHistory_Currency_8",
  "InvtHistory_Date_8",
  "InvtHistory_Series_8",
  "InvtHistory_InvGP_8",
  "InvtHistory_RoundSize_8",
  "InvtHistory_PostMoney_8",
  "InvtHistory_Currency_9",
  "InvtHistory_Date_9",
  "InvtHistory_Series_9",
  "InvtHistory_InvGP_9",
  "InvtHistory_RoundSize_9",
  "InvtHistory_PostMoney_9",
  "Textboxes_Overview",
  "Textboxes_Overview_BusinessModel",
  "Textboxes_Overview_InvestmentThesis",
  "Textboxes_Overview_Management",
  "Textboxes_Overview_Shareholders",
  "Textboxes_Overview_Influence",
  "BS_Runway",
  "Valuation_Multiple",
  "EmpCnt_CurrentFYE",
  "EmpCnt_NextFYE",
  "EmpCnt_CurrentFY",
  "EmpCnt_LastFY",
  "EmpCnt_LastFY01",
  "EmpCnt_LastFY02",
  "EmpCnt_LastFY03",
  "EmpCnt_Initial",
  "TopLine_CurrentFY_YTD",
  "TopLine_CurrentFYE",
  "TopLine_NextFYE",
  "TopLine_LastFY",
  "TopLine_LastFY01",
  "TopLine_LastFY02",
  "TopLine_LastFY03",
  "TopLine_Initial",
  "Profitability_CurrentFY0YTD",
  "Profitability_CurrentFYE",
  "Profitability_NextFYE",
  "Profitability_LastFY",
  "Profitability_LastFY01",
  "Profitability_LastFY02",
  "Profitability_LastFY03",
  "Profitability_Initial",
  "EqVal_Current",
  "EqVal_Current_FYE",
  "EqVal_Next_FYE",
  "EqVal_LastFY",
  "EqVal_LastFY01",
  "EqVal_LastFY02",
  "EqVal_LastFY03",
  "EqVal_Initial",
  "NetDebtCash_Current_FY_YTD",
  "NetDebtCash_Current_FYE",
  "NetDebtCash_Next_FYE",
  "NetDebtCash_LastFY",
  "NetDebtCash_LastFY_1",
  "NetDebtCash_LastFY_2",
  "NetDebtCash_LastFY_3",
  "NetDebtCash_Initial",
  "Textboxes_QtrUpdate",
  "Textboxes_Milestones_ST",
  "Textboxes_Outlook_LT",
  "EntValue_Current",
  "EntValue_LastFY",
  "EntValue_LastFY01",
  "EntValue_LastFY02",
  "EntValue_LastFY03",
  "EntValue_Initial",
  "Exp_Final_Return_MOIC",
  "Exp_Final_Return_Tier",
  "Exp_Final_Return_delta",
  "Exp_Final_Return_Final",
  "Exp_Final_Return_Excess_Shortfall",
  "Exp_Final_Return_TC_Rating",
  "company_ID",
];
