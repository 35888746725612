import { CSSProperties, useState } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import {
  IPortfolioStatic,
  PortfolioStaticProps,
} from "../../interfaces/portfolioStatic.interface";
import DynamicForm from "../../pages/pages/Add_updateForm";
import { toast } from "react-toastify";

function PortfolioStatic({ id }: PortfolioStaticProps) {
  const [fieldData, setFieldData] = useState({});
  const [message, setMessage] = useState("");
  const [newData, setNewData] = useState(false);
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "right", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%", // Make input take full width of its cell
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "24px",
      cursor: "pointer",
      height: "48px",
      fontWeight: "bold",
    },
  };
  function createFieldData(responseData: IPortfolioStatic): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | File | Number;
    };
  } {
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | File | Number;
      };
    } = {};
    setMessage("");
    Object.keys(responseData).forEach((key) => {
      if (
        key === "_id" ||
        key === "ReportingDateKey" ||
        key === "ReportingDateString"
      ) {
        // Exclude specific fields from being added to fieldData
      } else {
        if (key === "portfolio_companyLogo") {
          fieldData[key] = {
            type: "file",
            defaultValue: responseData[key as keyof IPortfolioStatic] as
              | File
              | undefined,
          };
        } else {
          const value = responseData[key as keyof IPortfolioStatic];
          if (typeof value === "number") {
            fieldData[key] = {
              type: "number",
              defaultValue: value,
            };
          } else {
            fieldData[key] = {
              type: "text",
              defaultValue: value as string | undefined,
            };
          }
        }
      }
    });
    return fieldData;
  }
  // Define an async function inside useEffect
  const fetchData = async () => {
    if (id == null || id === "") {
      setMessage("please select a valid id");
      return;
    } else {
      try {
        setFieldData({});
        setNewData(false);
        // Use params to send query parameters with a GET request
        const response = await axiosInstance.get("/portfolio-static/" + id);
        if (response.data === "error") {
          setMessage("Error in fetching data");
          // Handle error
        } else {
          if (response.data.data.length === 0) {
            setMessage("There is no data with the given Date");
            setFieldData({});
          } else {
            const responseData = response.data.data[0]; // Assuming this is your response data
            setFieldData(createFieldData(responseData));
            setMessage("");
          }

          // Handle success
        }
      } catch (err) {
        setMessage("Error in fetching data");
        console.error(err);
      }
    }
  };
  const handleChangeData = async (
    formDataAll: {
      [fieldName: string]: string;
    },
    updateType: String
  ) => {
    if (updateType === "add") {
      const form = new FormData();
      Object.keys(formDataAll).forEach((key) => {
        form.append(key, formDataAll[key]);
      });
      const response = await axiosInstance.post("/add_portfolioStatic", form, {
        headers: {
          "Content-Type": "multipart/form-data", // or you can omit this line
        },
      });
      if (response.data === "error") {
        setMessage("Error while updating Data.");
      } else {
        setFieldData({});
        setMessage("Data updated successfully");
      }
    } else if (updateType === "update") {
      try {
        const {
          fund_ID: idToRemove,
          Reporting_Date: dateToRemove,
          ...updatedFormData
        } = formDataAll;
        const form = new FormData();
        form.append("company_ID", id);
        form.append("file", updatedFormData.portfolio_companyLogo);
        delete updatedFormData.portfolio_companyLogo;
        form.append("formData", JSON.stringify(updatedFormData));
        const response = await axiosInstance.post(
          "/update_portfolioStatic",
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data", // or you can omit this line
            },
          }
        );
        if (response.data === "error") {
          toast.error("Error while updating Data.");
          setMessage("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
          setMessage("Data updated successfully");
        }
      } catch (err) {
        console.error(err);
        toast.error("Error while updating Data.");
        setMessage("Error while updating Data.");
      }
    }
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.button} onClick={fetchData}>
            Get previous data
          </button>
        </div>
        {/* <div style={(styles.buttonContainer, { width: "50%" })}>
          <button type="submit" style={styles.button} onClick={fetchData2}>
            Add new Data
          </button>
        </div> */}
      </div>
      {Object.keys(fieldData).length !== 0 && (
        <>
          <DynamicForm
            fieldData={fieldData}
            changedata={handleChangeData}
            staticData={!newData}
            newData={newData}
            reportingDate=""
          />
        </>
      )}
      {/* {message !== "" && <>{message}</>}{" "} */}
    </div>
  );
}
export default PortfolioStatic;
