import { useState, useRef, useEffect } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import {
  FormControl,
  Grid,
  Divider as MuiDivider,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import CustomProgressBar from "../../components/CustomProgressBar";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddExtraction() {
  const { user } = useAuth0();
  const [userEmail, setUserEmail] = useState("");
  const [data, setData] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fundName, setFundName] = useState("");
  const [summary, setSummary] = useState("");
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [fundNames, setFundNames] = useState([]);
  const quarters = [
    { name: "Q1", value: "03-31" },
    { name: "Q2", value: "06-30" },
    { name: "Q3", value: "09-30" },
    { name: "Q4", value: "12-31" },
  ];
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);

  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    setFundNames(response.data.data);
    setFundName(response.data.data[0]["fund_ID"]);
  };
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    getFundNames();
    // eslint-disable-next-line
  }, []);
  const resetData = () => {
    setData("");
    setIsButtonDisabled(false);
    setFile(null);
    setSummary("");
    setSelectedQuarter("");
    setSelectedYear("");
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const runscript = async () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 10000);
    if (file) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      try {
        toast.info(
          "Your file is being processed. You will receive an email shortly for updates"
        );
        setSummary(
          "Your file is being processed. You will receive an email shortly for updates"
        );
        const formData = new FormData();
        const name = file.name.slice(0, -4);
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
        formData.append("timestamp", time);
        formData.append("fund_name", fundName);
        formData.append("file_name", name);
        formData.append("email", userEmail);
        formData.append("date", selectedYear + "-" + selectedQuarter);
        const response = await axiosInstance.post(
          "/runPDFextraction",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            params: {
              timestamp: time,
              name: name,
            },
          }
        );
      } catch (err) {
        toast.error("Error Processing File. Please try again later.");
      }
    } else {
      toast.error("Please select a file to process");

    }
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };
  const handleQuarterChange = (event: any) => {
    setSelectedQuarter(event.target.value);
  };
  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value);
  };
  const handleFundChange = (event: any) => {
    setFundName(event.target.value);
  };
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      <Helmet title="Alerts" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Quarterly Report
      </Typography>

      <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            workFlow
          </Link>
          <Typography>Add Report</Typography>
        </Breadcrumbs>

        <CustomProgressBar progress={50} />
      </div>


      <Divider my={6} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundName}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundNames.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select reporting quarter and year:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Year Selector */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300, // Adjust max height to your preference
                          },
                        },
                      }}
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              // marginLeft: "25%",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "50px",
                border: "2px dashed gray",
                padding: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input
            >
              {file ? file.name : "Drag & Drop or Click to Select a File"}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                onClick={(e: any) => (e.target.value = "")}
                ref={inputFileRef} // Attach the ref to the input
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <TextField
                fullWidth
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                // inputRef={inputEmail}
                type="email"
                label="Email Address"
                variant="outlined"
                placeholder="email@example.com"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                disabled={isButtonDisabled}
                onClick={() => {
                  if (selectedQuarter && selectedYear) {
                    runscript();
                  } else {
                    toast.error("Please select a quarter and year");
                    // alert("Please select a quarter and year");
                  }
                }}
                style={{
                  backgroundColor: isButtonDisabled ? "#9E9E9E" : "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: isButtonDisabled ? "default" : "pointer",
                  outline: "none",
                  marginRight: "10px", // Add some space between the buttons
                }}
              >
                Process
              </button>
              <button
                onClick={resetData}
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                Reset
              </button>
            </div>

            {/* <div
          style={{
            alignContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "10%",
          }}
        >
          <div style={{ paddingTop: "10px", marginRight: "4%" }}>{data}</div>
          {data ===
            "Unfortunately we cannot process encrypted files at this time. Please come back in a few weeks for the next release" && (
            <button
              onClick={resetData}
              style={{
                marginLeft: "40%",
                marginTop: "20px",
                backgroundColor: "#D32F2F",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
              }}
            >
              Reset
            </button>
          )}
        </div> */}
          </div>
        </div>
      </div>

      {/* {summary && (
        <>
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid #e0e0e0",
              padding: "15px",
              textAlign: "justify",
            }}
          >
            <b>Summary: </b>
            {summary}
          </div>
          <button
            onClick={resetData}
            style={{
              marginLeft: "40%",
              marginTop: "20px",
              backgroundColor: "#D32F2F",
              color: "white",
              border: "none",
              padding: "12px 24px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s, box-shadow 0.2s",
              cursor: "pointer",
              outline: "none",
            }}
          >
            Reset
          </button>
        </>
      )} */}
    </div>
  );
}

export default AddExtraction;
