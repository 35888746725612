import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TextField,
  Typography,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Button,
} from "@mui/material";
import axiosInstance from "../../utils/axios/axios.instance";
import { IPortfolioStatic } from "../../interfaces/portfolioStatic.interface";
import PortfolioSelectionComponent from "../portfolioSelection";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { IFundStatic } from "../../interfaces/fundStatic.interface";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
interface RowData {
  [key: string]: string | number; // Assuming all values are either string or number for simplicity
}

interface TableData {
  [key: string]: any;
}
interface FundTableProps {
  data: TableData[];
}

const PortfolioReviewTable: React.FC<FundTableProps> = (props) => {
  const [firstRow, setFirstRow] = useState<RowData[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [fundNames, setFundNames] = useState<Record<string, number>[]>([]);
  const [portfolioNames, setPortfolioNames] = useState<
    Record<string, number>[]
  >([]);
  const [selectionData, setSelectionData] = useState({
    fund_ID: "",
    company_ID: "",
    date: "",
  });

  useEffect(() => {
    getFundNames();
  }, []);

  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;
      const portfolioNames = responseData.map((portfolio: IPortfolioStatic) => {
        return {
          [portfolio.portfolio_companyName as string]: portfolio.company_ID,
        };
      });
      if (Array.isArray(portfolioNames) && portfolioNames.length > 0 && typeof portfolioNames[0] === 'object' && portfolioNames[0] !== null) {
        const values = Object.values(portfolioNames[0]) as number[];
        if (values.length > 0) {
          setSelectionData({
            fund_ID: id,
            company_ID: values[0].toString(),
            date: "31-03-2021",
          });
        } else {
          console.error('First object in the array is empty.');
        }
      } else {
        console.error('portfolioNames is not a valid array of objects.');
      }
      setPortfolioNames(portfolioNames);
    } catch (error) {
      console.error(error);
    }
  };

  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names");
      const responseData = response.data.data;
      const fundNames = responseData.map((fund: IFundStatic) => {
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      setFundNames(fundNames);
      if (fundNames.length > 0) {
        const firstFundId = Object.values(fundNames[0])[0] as string;
        getPortfolioNames(firstFundId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (value: string, idx: number, key: string) => {
    const updatedRows = [...firstRow];
    updatedRows[idx][key] = value;
    setFirstRow(updatedRows); // Set the new state
  };
  const handleSelectionChange = (data: {
    fund_ID: string;
    company_ID: string;
    date: string;
  }) => {
    if (data.fund_ID !== selectionData.fund_ID && data.fund_ID !== "") {
      getPortfolioNames(data.fund_ID);
    }
    setSelectionData(data);
  };
  const reverseUpdateFirstRow = (data: any, fieldName: string) => {
    let result: any = {};
    for (let i = 1; i <= 9; i++) {
      result[`${fieldName}-${i}`] = data[`Fields-${i}`];
    }
    return result;
  };
  const updateFirstRow = (
    data: any,
    fieldName: string,
    headingName: string
  ) => {
    return {
      Heading: headingName,
      "Fields-1": data[fieldName + "-1"],
      "Fields-2": data[fieldName + "-2"],
      "Fields-3": data[fieldName + "-3"],
      "Fields-4": data[fieldName + "-4"],
      "Fields-5": data[fieldName + "-5"],
      "Fields-6": data[fieldName + "-6"],
      "Fields-7": data[fieldName + "-7"],
      "Fields-8": data[fieldName + "-8"],
      "Fields-9": data[fieldName + "-9"],
    };
  };
  const reverseUpdateAllRows = (rows: any[], fields: string[]) => {
    let result: any = {};
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const fieldName = fields[i];
      const reversedRow = reverseUpdateFirstRow(row, fieldName);
      result = { ...result, ...reversedRow };
    }
    return result;
  };
  const PostDataToServer = async () => {
    try {
      firstRow.forEach(async (row) => {
        delete row.Heading;
      });

      // Example usage:
      const fields = [
        "InvtHistory_Date",
        "InvtHistory_Series",
        "InvtHistory_InvGP",
        "InvtHistory_RoundSize",
        "InvtHistory_PostMoney",
        "InvtHistory_Currency",
      ];
      const reversedData = reverseUpdateAllRows(firstRow, fields);
      const form = new FormData();
      form.append("formData", JSON.stringify(reversedData));
      form.append("Reporting_Date", selectionData.date);
      form.append("fund_ID", selectionData.fund_ID);
      form.append("company_ID", selectionData.company_ID);
      const response = await axiosInstance.patch(
        "/updatePortfolioReviewByID",
        form
      );
      setIsSelected(false);
      if (response.data.data === "error") {
        toast.error("Error while updating Data.");
      } else {
        toast.success("Data updated successfully");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        getPortfolioReviewData();
      }, 3000);
    }
  };
  const getPortfolioReviewData = async () => {
    try {
      const response = await axiosInstance.get(
        "/getPortfolioReviewCurrentData/" +
        selectionData.date +
        "/" +
        selectionData.company_ID +
        "/" +
        selectionData.fund_ID
      );
      const responseData = response.data.data[0];
      setIsSelected(true);
      const updatedRows = [
        updateFirstRow(responseData, "InvtHistory_Date", "History Date"),
        updateFirstRow(responseData, "InvtHistory_Series", "Series"),
        updateFirstRow(responseData, "InvtHistory_InvGP", "Invested by GP"),
        updateFirstRow(
          responseData,
          "InvtHistory_RoundSize",
          "FInal size of round"
        ),
        updateFirstRow(
          responseData,
          "InvtHistory_PostMoney",
          "Post money valuation"
        ),
        updateFirstRow(responseData, "InvtHistory_Currency", "Currency"),
      ];
      setFirstRow(updatedRows);
    } catch (error) {
      setIsSelected(false);
      console.error(error);
    }
  };

  const renderTableContent = () => {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div>
            <PortfolioSelectionComponent
              fundNames={fundNames}
              portfolioNames={portfolioNames}
              onSelectionChange={handleSelectionChange}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={getPortfolioReviewData}
            style={{ margin: "5px", marginLeft: "20px", marginBottom: "20px", borderRadius: "24px", backgroundColor: "#007bff" }}
            variant="contained"
          >
            Get Data
          </Button>
          {/* <button onClick={getPortfolioReviewData}>get data</button> */}
        </div>
        {isSelected && (
          <div
            style={{
              padding: "10px",
            }}
          >
            <TableBody>
              {firstRow.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    style={{
                      backgroundColor: idx % 2 === 0 ? "#FFFFFF" : "#EFF5FF",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                  >
                    <Typography variant="subtitle2">
                      {" "}
                      {item.Heading}{" "}
                    </Typography>
                  </TableCell>
                  {Object.entries(item).map(([key, value], index) => {
                    if (key === "Heading") return null;
                    return (
                      <TableCell
                        key={index}
                        style={{
                          backgroundColor:
                            idx % 2 === 0 ? "#FFFFFF" : "#EFF5FF",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                          textAlign: index !== 0 ? "center" : "left",
                        }}
                      >
                        <TextField
                          fullWidth
                          variant="standard"
                          value={value != null ? value.toString() : ""}
                          InputProps={{
                            style: {
                              textAlign: "center",
                              alignItems: "center", // This only works if height is set or in a flex container
                              display: "flex", // Ensures vertical centering
                            },
                          }}
                          onChange={(e) =>
                            handleInputChange(e.target.value, idx, key)
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Helmet title="Portfolio Review" />
      <Typography variant="h3" gutterBottom display="inline">
        Portfolio Review Form
      </Typography>
      <div
        style={{
          marginTop: "0px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            workFlow
          </Link>
          <Typography>Portfolio Review Update</Typography>
        </Breadcrumbs>
      </div>
      <Divider my={6} />
      <Paper style={{ width: "100%" }}>
        <Table stickyHeader>{renderTableContent()}</Table>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            paddingBottom: "30px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={PostDataToServer}
            style={{ margin: "5px", marginLeft: "20px", backgroundColor: "#007bff", borderRadius: "24px"}}
            variant="contained"
          >
            Update
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default PortfolioReviewTable;
