export interface CapitalCallReport {
  _id: string;
  cc_Date: string;
  cc_ID: string;
  Fund_Name: string;
  fund_currency: string;
  cc_investment: string;
  cc_managementFees: string;
  cc_orgFees: string;
  cc_partnershipExp: string;
  cc_totalCapitalCalled: string;
  fund_ID: string;
}
export const fieldNames: { [key in keyof CapitalCallReport]: string } = {
  _id: "ID",
  cc_Date: "Capital Call Date",
  cc_ID: "Capital Call ID",
  Fund_Name: "Fund Name",
  fund_currency: "Fund Currency",
  cc_investment: "Investment",
  cc_managementFees: "Management Fees",
  cc_orgFees: "Organization Fees",
  cc_partnershipExp: "Partnership Expenses",
  cc_totalCapitalCalled: "Total Capital Called",
  fund_ID: "Fund ID",
};
export const orderArray: (keyof CapitalCallReport)[] = [
  "_id",
  "cc_Date",
  "cc_ID",
  "Fund_Name",
  "fund_currency",
  "cc_investment",
  "cc_managementFees",
  "cc_orgFees",
  "cc_partnershipExp",
  "cc_totalCapitalCalled",
  "fund_ID",
];
