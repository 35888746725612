import React, {
  CSSProperties,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Button,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import axiosInstance from "../../utils/axios/axios.instance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink, useNavigate } from "react-router-dom";
import { convertStringToDate } from "../../utils/dateUtil";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { toast } from "react-toastify";
import CustomProgressBar from "../../components/CustomProgressBar";
import {
  FundName,
  CompanyName,
  FormDataObject,
} from "../../interfaces/updateScript.interface";
import {
  keyOrder,
  constKey,
  FundkeyOrder,
  keyDisplayNames,
  PortfolioKeyNames,
} from "../../components/UpdateScript/HelpingFunctions/updateScript";
import PortfolioForm from "../../components/UpdateScript/component/ScriptFrom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const form = React.createRef<HTMLFormElement>();

function UpdateScriptData() {
  const [selection, setSelection] = React.useState("fund");
  const handleAlignment = (event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setSelection(newAlignment);
      setIsSelected(false);
      setSelectedValue("");
    }
  };
  const [fundData, setFundData] = useState([]);
  const [isFundUpdate, setisFundUpdate] = useState(false);
  const [isCompanyUpdate, setIsCompanyUpdate] = useState(false);
  const [date, setDate] = useState<Date | null>(new Date());
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isFundSelected, setFundIsSelected] = useState<boolean>(false);
  const [fundUpdate, setFundUpdate] = useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>("");
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [inputvalue, setInputValue] = useState<any>({});
  const [fundInputValue, setFundInputValue] = useState<any>({});
  const inputRefs = useRef<any>({});
  const [fundName, setFundName] = useState<string>("");
  const [portfolioList, setPortfolioList] = useState<any[]>([]);
  const [staticValue, setStaticValue] = useState([
    {
      Reporting_Date: "",
      Textboxes_Overview: "",
      PublicMkt_Status: "",
      portfolio_companyCountry: "",
      portfolio_firstInvestmentDate: "",
      portfolio_exitDate: "",
      portfolio_realizedGainLoss: "",
      portfolio_unrealizedCost: "",
      portfolio_unrealizedNAV: "",
      portfolio_currency: "",
      portfolio_companyName: "",
      portfolio_FiscalYearEnd: "",
      positive_impact: "",
      portfolio_realizedValue: "",
      portfolio_geoLocation: "",
      portfolio_companyCountries: "",
      portfolio_companyOverview: "",
      portfolio_companyState: "",
      portfolio_sector: "",
      Textboxes_QtrUpdate: "",
      portfolio_revenue: "",
      portfolio_ebitda: "",
      portfolio_initialInvt_FDO: "",
      created_At: "",
      run_id: "",
      portfolio_CapitalInvested_NonDil: "",
      portfolio_reservedCapital: "",
      portfolio_moicFinal: "",
      portfolio_unrealizedNAV_NonDil: "",
      portfolio_initialInvt_NonDil: "",
      portfolio_initialInvt_Dilutive: "",
      portfolio_capitalInvested: "",
      portfolio_capitalReserved: "",
      portfolio_ROI: "",
      portfolio_unrealizedGainLoss: "",
      portfolio_totalGainLoss: "",
      portfolio_moc: "",
      portfolio_realizedCost: "",
      portfolio_GP_Lead: "",
      Fund_Name: "",
      fund_ID: "",
      company_ID: "",
    },
  ]);
  const [fundStaticData, setFundStaticData] = useState<any[]>([
    {
      _id: "66100a08d845950b84767df1",
      Reporting_Date: "",
      fund_strategy: "",
      fund_region: "",
      fund_fundSizeFinalClose: "",
      fund_numberOfInvestmentProjected: "",
      LP_name: "",
      lp_investmentStake: "",
      fund_nav: "",
      fund_dpi: "",
      fund_tvpi: "",
      // fund_capitalCalled: "",
      fund_recallableDistributions: "",
      fund_gpCarriedDistribution: "",
      fund_distributed: "",
      fund_IRR: "",
      created_At: "",
      record_type: "",
      fund_netIRR: "",
      fund_sectorFocus: "",
      fund_stage: "",
      fund_investmentAdvisor: "",
      fund_investmentManager: "",
      fund_Life_LPA: "",
      fund_gpCommitment: "",
      fund_carriedInterest: "",
      fund_hurdleRate: "",
      fund_removalWithCause: "",
      fund_removalWithoutCause: "",
      fund_treatmentOfCarriedInterestOnRemoval: "",
      fund_keyPersonProvision: "",
      fund_governingLawAndJurisdiction: "",
      fund_teamBackground: "",
      fund_commitment: "",
      fund_vintage: "",
      fund_endDate: "",
      fund_currency: "",
      fund_Fees: "",
      fund_Pref: "",
      fund_Carry: "",
      fund_geoLocation: "",
      Textboxes_Outlook: "",
      Textboxes_FundPipe: "",
      "Textboxes_Co-InvestPipe": "",
      fund_capitalReserved: "",
      fund_capitalAvailable: "",
      Textboxes_Exits: "",
      run_id: "",
      Fund_Name: "",
      // fund_valueNetAsset: "",
      fund_ID: "",
    },
  ]);
  const [runIDs, setRunIDs] = useState<any[]>([]);
  const [currentRunID, setCurrentRunID] = useState("");

  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const theme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected, &.Mui-selected:hover": {
              // Styles applied to the button when it is in the selected state
              color: "white",
              backgroundColor: "#1976d2", // A shade of blue
            },
          },
        },
      },
    },
  });
  const [extractedData, setExtractedData] = useState<any[]>([]);
  const [filename, setFileName] = useState<FundName[]>([]);
  const [companyName, setCompanyName] = useState<CompanyName[]>([]);

  useEffect(() => {
    document.title = "AI Extracted Data";
    getFileNames();
  }, []);
  const UpdateCompanyData = async () => {
    try {
      let formData2: FormDataObject = {};
      formData2 = inputvalue;

      const form = new FormData();
      delete formData2._id;
      // delete formData2.fund_ID;
      formData2.fund_ID = selectedFundId;
      debugger;
      form.append("formData", JSON.stringify(formData2));
      if (isCompanyUpdate) {
        const response = await axiosInstance.patch("/mix-portfolio-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          // setFieldData({});
          setIsSelected(false);
          toast.success("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.post("/mix-portfolio-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setIsCompanyUpdate(true);

          // setFieldData({});
          setIsSelected(false);
          toast.success("Data updated successfully");
        }
      }
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        getData(selectedCompanyId);
      }, 3000);
    }
  };
  const updateFundData = async () => {
    try {
      let formData2: FormDataObject = {};
      formData2 = fundInputValue;

      formData2["fund_capitalCalled"] = (
        Number(formData2["fund_called"]) -
        Number(formData2["fund_recallableDistributions"])
      ).toString();

      formData2["fund_valueDistributed"] = (
        Number(formData2["fund_distributed"]) -
        Number(formData2["fund_recallableDistributions"]) -
        Number(formData2["fund_gpCarriedDistribution"])
      ).toString();
      formData2["fund_valueNetAsset"] = (
        Number(formData2["fund_nav"]) -
        Number(formData2["fund_netAssetValueGPCarried"])
      ).toString();

      const form = new FormData();
      delete formData2._id;
      form.append("fund_ID", fundInputValue["fund_ID"]);
      form.append("Reporting_Date", fundInputValue["Reporting_Date"]);
      form.append("formData", JSON.stringify(formData2));
      if (!isFundUpdate) {
        const response = await axiosInstance.post("/mix-fund-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFundUpdate(true);
          setisFundUpdate(true);
          toast.success("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.patch("/mix-fund-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFundUpdate(true);
          toast.success("Data updated successfully");
        }
      }
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        getCompanyData();
        setFundUpdate(false);
      }, 3000);
    }
  };
  function areSameDay(d1: Date, d2: Date) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }
  const getCompanyData = async (runId?: string) => {
    if (!selectedFundId) {
      alert("Please select Fund first");
      return;
    }
    try {
      const response = await axiosInstance.get(
        "/extractedCompanyName/" +
          selectedFundId +
          "/" +
          `${date?.toISOString().split("T")[0].split("-").reverse().join("-")}${
            runId ? `?run_id=${runId}` : ""
          }`
      );
      const FundData = response.data.data.filter(
        (item: any) => item.record_type === "fund"
      );

      if (FundData.length !== 0) {
        var date1 = date;
        var parts = FundData[0].Reporting_Date.split("-");
        var date2 = new Date(parts[2], parts[1] - 1, parts[0]);
        const isSameDay = areSameDay(date1 as Date, date2);
        isSameDay ? setFundData(FundData) : setFundData([]);
      } else {
        setFundData([]);
      }
      setCompanyName(response.data.data);
      setSelectedValue("");
      response.data.data != null
        ? setFundIsSelected(true)
        : setFundIsSelected(false);
    } catch (error) {
      console.error(error);
    }
  };
  const [selectedValue, setSelectedValue] = useState("");
  const getData = async (id: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/extractedCompanyData/" + id + "/" + dat
      );
      let newData = { ...response.data.data[0] };
      delete newData.record_type;

      for (const key in newData) {
        if (Array.isArray(newData[key])) {
          if (
            newData[key].length > 0 &&
            typeof newData[key][0] === "object" &&
            !Array.isArray(newData[key][0])
          ) {
            const firstObj = newData[key][0];
            const firstKey = Object.keys(firstObj)[0]; // Get the first key of the first object
            newData[key] = firstObj[firstKey]; // Update the key to the value of the first key in the first object of the array
          } else {
            // If it's not an array of objects, or the array is empty, just use the first element
            newData[key] = newData[key][0] || "";
          }
        }
      }
      setExtractedData(newData);
      response.data.data[0] != null ? setIsSelected(true) : setIsSelected(true);
    } catch (error) {
      console.error(error);
    }
  };
  const getFileNames = async () => {
    try {
      const response = await axiosInstance.get("/extractedFundName");
      const responseData = await response.data.data;
      setFileName(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePortfolioChanage = (key: string, value: string) => {
    setInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
    setTimeout(() => {
      if (inputRefs.current[key]) {
        const input = inputRefs.current[key];
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    }, 0);
  };
  const handleInputChange = (key: string, value: string) => {
    setFundInputValue((prevInputValue: any) => {
      const newValue = { ...prevInputValue, [key]: value };
      return newValue;
    });
    setTimeout(() => {
      if (inputRefs.current[key]) {
        const input = inputRefs.current[key];
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    }, 0);
  };

  const getFundQtrData = async (ReportingDate: Date, FundID: string) => {
    const midDate = ReportingDate?.toISOString().split("T")[0];
    const converted = midDate?.split("-").reverse().join("-");
    try {
      const response = await axiosInstance.get(
        "/getFundQtr/" + converted + "/" + FundID
      );
      const staticData = await axiosInstance.get("/fund-static/" + FundID);
      const responseData = await response.data.data;
      const fundstaticData = await staticData.data.data;
      if (responseData.length === 0) {
        setFundUpdate(false);
        const data = [
          {
            Reporting_Date: converted,
            fund_strategy: "",
            Fund_Name: fundName,
            fund_ID: FundID,
            AUC_GPDist: "",
            AUC_GPNav: "",
            AUC_Reserves: "",
            fund_OrgFees_toDate: "",
            fund_PartnerExp_toDate: "",
            fund_MgmtFees_toDate: "",
            Textboxes_Strategy: "",
            Textboxes_Outlook: "",
            Textboxes_QtrlyUpdate: "",
            Textboxes_FundPipe: "",
            "Textboxes_Co-InvestPipe": "",
            Textboxes_Exits: "",
            Textboxes_ToBeRealized: "",
            fund_nav: "",
            fund_dpi: "",
            fund_tvpi: "",
            ReportingDateKey: "",
            fund_called: "",
            fund_recallableDistributions: "",
            fund_distributed: "",
            fund_capitalCalled: "",
            fund_capitalReserved: "",
            // fund_valueDistributed: "",
            fund_numberOfInvestmentToDate: "",
            fund_gpCarriedDistribution: "",
            fund_uncalledCapital: "",
            fund_netIRR: "",
            fund_rvpi: "",
            fund_numberOfInvestmentProjected: "",
            fund_capitalAvailable: "",
            fund_totalOtherAssetsLiabilities: "",
            fund_ficialSummary: "",
            fund_keyDriversPreviousQTR: "",
            fund_macroInductryDrivers: "",
            fund_extraOrdinaryMovements: "",
            fund_riskDisclosure: "",
            fund_gpUpdates: "",
            fund_corporateActions: "",
            fund_valueNetAsset: "",
            fund_IRR: "",
          },
        ];
        const combinedData = {
          ...data[0],
          ...fundstaticData[0],
        };
        setisFundUpdate(false);
        setFundInputValue(combinedData);
      } else {
        const combinedData = {
          ...responseData[0],
          ...fundstaticData[0],
        };
        setisFundUpdate(true);
        setFundInputValue(combinedData);
        setisFundUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPortfolioData = async (
    ReportingDate: Date,
    companyID: string,
    companyName: string
  ) => {
    const midDate = ReportingDate?.toISOString().split("T")[0];
    const converted = midDate?.split("-").reverse().join("-");
    try {
      const response = await axiosInstance.get(
        "/getPortfolioQtr/" + converted + "/" + companyID + "/" + selectedFundId
      );
      const staticdata = await axiosInstance.get(
        "/portfolio-static/" + companyID
      );

      const responseData = await response.data.data;
      const portfolioStatic = await staticdata.data.data;
      if (responseData.length === 0) {
        const data = [
          {
            _id: "",
            Reporting_Date: converted,
            Fund_Name: fundName,
            fund_ID: selectedFundId,
            portfolio_companyName: companyName,
            Main_Company_Name: companyName,
            company_ID: companyID,
            ReportingDateKey: "",
            ReportingDateString: "",
            portfolio_firstInvestmentDate: "",
            portfolio_exitDate: "",
            portfolio_unrealizedCost: "",
            portfolio_unrealizedNAV: "",
            portfolio_realizedValue: "",
            portfolio_latestNav: "",
            portfolio_proceeds: "",
            portfolio_fmv: "",
            portfolio_tvpi: "",
            portfolio_revenue: "",
            portfolio_ebitda: "",
            portfolio_holdingPeriod: "",
            portfolio_totalCumulativeValue: "",
            portfolio_initialInvt_FDO: "",
            portfolio_keyShareholders: "",
            portfolio_arr: "",
            portfolio_ebit: "",
            portfolio_pbt: "",
            portfolio_grossMargin: "",
            Currency: "",
            portfolio_GP_Lead: "",
            InvCostoDate_Dilutive: "",
            Textboxes_QtrUpdate: "",
            FDO: "",
            portfolio_realizedCost: "",
            portfolio_realizedGainLoss: "",
            portfolio_CapitalInvested_NonDil: "",
            portfolio_reservedCapital: "",
            portfolio_moicFinal: "",
            portfolio_unrealizedNAV_NonDil: "",
            portfolio_initialInvt_NonDil: "",
            portfolio_initialInvt_Dilutive: "",
            portfolio_capitalInvested: "",
            portfolio_capitalReserved: "",
            portfolio_ROI: "",
            portfolio_unrealizedGainLoss: "",
            portfolio_totalGainLoss: "",
            portfolio_moc: "",
            PublicMkt_SharePrice: "",
            portfolio_FiscalYearEnd: "",
            PublicMkt_Exchange: "",
            PublicMkt_Ticker: "",
            PublicMkt_Status: "",
          },
        ];
        const combinedData = {
          ...data[0],
          ...portfolioStatic[0],
        };
        setInputValue(combinedData);
        setIsCompanyUpdate(false);
      } else {
        const combinedData = {
          ...responseData[0],
          ...portfolioStatic[0],
        };
        setInputValue(combinedData);
        setIsCompanyUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };
  const getRefData = async (id: string, date: Date) => {
    try {
      const response = await axiosInstance.get(
        "/pastCompanyData/" +
          id +
          "/" +
          date?.toISOString() +
          "/" +
          selectedFundId
      );
      const companyData = response.data.data[0];
      const updatedStaticValue = staticValue.map((item) => {
        Object.keys(item).forEach((key) => {
          // if (key === "Textboxes_Overview") {
          //   item[key as keyof typeof item] = companyData["Textboxes_QtrUpdate"];
          // } else
          if (companyData.hasOwnProperty(key)) {
            item[key as keyof typeof item] = companyData[key];
          }
        });
        return item;
      });
      setStaticValue(updatedStaticValue);
    } catch (error) {
      console.error(error);
    }
  };
  const getRefData2 = async (id: string, ReportingDate: Date) => {
    try {
      const dateReporting = ReportingDate?.toISOString();
      const response = await axiosInstance.get(
        "/pastFundData/" + id + "/" + dateReporting
      );
      const companyData = response.data.data[0];
      const updatedStaticValue = fundStaticData.map((item) => {
        Object.keys(item).forEach((key) => {
          // if (key === "Textboxes_Overview") {
          //   item[key as keyof typeof item] = companyData["Textboxes_QtrUpdate"];
          // } else
          if (companyData.hasOwnProperty(key)) {
            item[key as keyof typeof item] = companyData[key];
          }
        });
        return item;
      });
      if (companyData) {
        setFundStaticData(updatedStaticValue);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;
      setPortfolioList(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (fundName) {
      axiosInstance
        .get("/extractedCompanyRunIds/" + selectedFundId)
        .then((response) => {
          setRunIDs(response.data.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundName]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        AI Extracted Data
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            workFlow
          </Link>
          <Typography>AI Extraction Update</Typography>
        </Breadcrumbs>

        <CustomProgressBar progress={100} />
      </div>

      <Divider my={6} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              {filename && (
                <div style={{ display: "flex" }}>
                  <label
                    style={{
                      marginRight: "10px",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Fund :
                  </label>
                  <select
                    style={{
                      backgroundColor: "#f2f2f2",
                      marginRight: "20px",
                      color: "#333",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      outline: "none",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                      width: "250px",
                      cursor: "pointer",
                    }}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      const selectedIndex = e.target.selectedIndex - 1;
                      if (selectedIndex >= 0) {
                        setSelectedFundId(e.target.value);
                        const date = convertStringToDate(
                          filename[selectedIndex].Reporting_Date
                        );
                        const fundName = filename[selectedIndex].Fund_Name;
                        setFundName(fundName);
                        setDate(date);
                      }
                    }}
                  >
                    <option value="">Please select fund</option>
                    {filename.map((fileName, index) => {
                      return (
                        <option key={index} value={filename[index].fund_ID}>
                          {filename[index].Fund_Name}
                        </option>
                      );
                    })}
                  </select>
                  <DatePicker
                    label="Reporting Date"
                    format="dd/MM/yyyy"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                  />
                  <Button
                    onClick={() => {
                      if (date) {
                        setIsSelected(false);
                        getPortfolioNames(selectedFundId);
                        getCompanyData(currentRunID);
                        getRefData2(selectedFundId, date);
                        getFundQtrData(date, selectedFundId);
                      } else {
                        alert("Please select company and date");
                      }
                    }}
                    style={{
                      margin: "5px",
                      marginLeft: "20px",
                      backgroundColor: "#007bff",
                      borderRadius: "24px",
                    }}
                    variant="contained"
                  >
                    Get Data
                  </Button>

                  {fundName && (
                    <select
                      style={{
                        backgroundColor: "#f2f2f2",
                        marginLeft: "30px",
                        color: "#333",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        outline: "none",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                        width: "200px",
                        cursor: "pointer",
                      }}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const selectedIndex = e.target.selectedIndex - 1;
                        if (selectedIndex >= 0) {
                          const selectedID = runIDs.find((id) => {
                            return id.run_id === e.target.value;
                          });
                          setCurrentRunID(selectedID.run_id);
                          getCompanyData(selectedID.run_id);
                        } else {
                          setCurrentRunID("");
                          getCompanyData();
                        }
                      }}
                    >
                      <option value="">Please select Run ID</option>
                      {runIDs.map((runID, index) => {
                        return (
                          <option key={index} value={runID.run_id}>
                            {runID.run_id}
                          </option>
                        );
                      })}
                    </select>
                  )}

                  <Button
                    startIcon={<ArrowBackIcon />}
                    variant="contained"
                    onClick={handleBack}
                    style={{
                      marginLeft: "auto",
                      borderRadius: "24px",
                      backgroundColor: "#007bff",
                      // padding: "12px 24px",
                    }}
                  >
                    Back
                  </Button>
                </div>
              )}
              <ThemeProvider theme={theme}>
                {isFundSelected && !fundUpdate && (
                  <div>
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      <ToggleButtonGroup
                        style={{ marginLeft: "10px", marginRight: "20px" }}
                        value={selection}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="text alignment"
                      >
                        <ToggleButton value="fund" aria-label="left aligned">
                          Update Fund
                        </ToggleButton>
                        <ToggleButton value="portfolio" aria-label="centered">
                          Update Portfolio
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {selection === "portfolio" && (
                        <>
                          <label
                            style={{
                              marginRight: "10px",
                              marginTop: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Select Company :
                          </label>
                          <select
                            style={{
                              backgroundColor: "#f2f2f2",
                              marginRight: "20px",
                              color: "#333",
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              outline: "none",
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                              width: "220px",
                              cursor: "pointer",
                            }}
                            value={selectedValue}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              if (e.target.value !== "") {
                                const id = e.target.value;
                                setSelectedValue(e.target.value);
                                if (id !== "" && date) {
                                  const selectedCompany = companyName.find(
                                    (company) => company.company_ID === id
                                  );
                                  getPortfolioData(
                                    date,
                                    e.target.value,
                                    selectedCompany?.portfolio_companyName || ""
                                  );
                                  getData(e.target.value);
                                  getRefData(e.target.value, date);
                                  setSelectedCompanyId(e.target.value);
                                }
                              } else {
                                setSelectedValue("");
                                setIsSelected(false);
                              }
                            }}
                          >
                            <option value="">please select company</option>
                            {portfolioList
                              .filter((key) => {
                                if (
                                  key.hasOwnProperty("portfolio_companyName")
                                ) {
                                  return true;
                                }
                                return false;
                              })
                              .map((company, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={company.company_ID}
                                  >
                                    {company.portfolio_companyName}
                                  </option>
                                );
                              })}
                          </select>
                        </>
                      )}
                    </div>
                    {isSelected
                      ? inputvalue && (
                          <div>
                            <div style={styles.buttonContainer}>
                              <Button
                                onClick={() => {
                                  UpdateCompanyData();
                                }}
                                style={{
                                  margin: "20px",
                                  backgroundColor: "#007bff",
                                  borderRadius: "24px",
                                }}
                                variant="contained"
                              >
                                {isCompanyUpdate
                                  ? "Update Portfolio"
                                  : "Add Portfolio"}
                              </Button>
                            </div>
                            <PortfolioForm
                              inputRefs={inputRefs}
                              inputvalue={inputvalue}
                              handlePortfolioChange={handlePortfolioChanage}
                              constKey={constKey}
                              PortfolioKeyNames={PortfolioKeyNames}
                              form={form}
                              staticValue={staticValue}
                              extractedData={extractedData}
                              keyOrder={keyOrder}
                              styles={styles}
                            />
                          </div>
                        )
                      : selection === "fund" &&
                        (fundStaticData.length === 0 ? (
                          <div
                            style={{
                              marginTop: "20px",
                              fontSize: "15px",
                            }}
                          >
                            No Fund Data Found
                          </div>
                        ) : (
                          <div>
                            <div style={styles.buttonContainer}>
                              <Button
                                onClick={() => {
                                  updateFundData();
                                }}
                                style={{
                                  margin: "20px",
                                  backgroundColor: "#007bff",
                                  borderRadius: "24px",
                                }}
                                variant="contained"
                              >
                                {isFundUpdate ? "Update Fund" : "Add Fund"}
                              </Button>
                            </div>
                            <form
                              ref={form}
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <div>
                                <table
                                  style={{
                                    textAlign: "left",
                                    width: "100%", // White background
                                    borderCollapse: "separate", // Allows the borders to be separated for spacing
                                    borderSpacing: "1px", // Sets the space between the cells
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          ...styles.tableLabel,
                                          width: "20%", // Set width to 25%
                                        }}
                                      >
                                        Key name
                                      </th>
                                      <th
                                        style={{
                                          ...styles.tableLabel,
                                          textAlign: "center",
                                          width: "30%", // Set width to 25%m
                                          marginLeft: "20px",
                                        }}
                                      >
                                        Update value manually
                                      </th>
                                      <th
                                        style={{
                                          ...styles.tableLabel,
                                          textAlign: "center",
                                          width: "25%", // Set width to 25%
                                        }}
                                      >
                                        AI extracted value
                                      </th>
                                      <th
                                        style={{
                                          ...styles.tableLabel,
                                          color: "grey",
                                          textAlign: "center",
                                          width: "25%", // Set width to 25%
                                        }}
                                      >
                                        Previous Entry
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(fundStaticData[0])
                                      .filter(
                                        (key) =>
                                          key !== "_id" &&
                                          key !== "created_At" &&
                                          key !== "run_id" &&
                                          key !== "record_type" &&
                                          key !== "fund_ID" &&
                                          !key.includes("pageNumber")
                                      )
                                      .sort(
                                        (a, b) =>
                                          FundkeyOrder.indexOf(a) -
                                          FundkeyOrder.indexOf(b)
                                      )
                                      .map((key, index) => {
                                        const file = fundData[0] as {
                                          [key: string]: any;
                                        };
                                        const refrence = fundStaticData[0] as {
                                          [key: string]: any;
                                        };
                                        if (key === "_id") return null;
                                        return (
                                          <tr
                                            key={index}
                                            style={{
                                              paddingTop: "30px",
                                              borderBottom: "1px solid #cccccc",
                                            }}
                                          >
                                            <td
                                              style={{
                                                ...styles.label,
                                                paddingBottom: "10px",
                                                width: "20%",
                                              }}
                                            >
                                              {keyDisplayNames[key] ||
                                                key.replace(/_/g, " ")}
                                            </td>
                                            <td>
                                              <TextField
                                                key={fundInputValue[key]}
                                                inputRef={(el) =>
                                                  (inputRefs.current[key] = el)
                                                }
                                                name={key}
                                                id={index.toString()}
                                                multiline
                                                label={key.replace(/_/g, " ")}
                                                style={{
                                                  width: "100%", // Make input take full width of its cell
                                                  textAlign: "justify",
                                                  marginBottom: "10px",
                                                  background: "#fff", // Adds a white background
                                                  borderColor: "#007bff", // Blue border color
                                                  borderWidth: "2px", // Border width
                                                  borderRadius: "4px", // Rounded corners
                                                  padding: "5px", // Internal padding
                                                }}
                                                value={fundInputValue[key]}
                                                type="text"
                                                onChange={(e) => {
                                                  handleInputChange(
                                                    key,
                                                    e.target.value
                                                  );
                                                  // setFundInputValue(
                                                  //   (prevInputValue: any) => ({
                                                  //     ...prevInputValue,
                                                  //     [key]: e.target.value,
                                                  //   })
                                                  // );
                                                }}
                                                disabled={constKey.includes(
                                                  key
                                                )}
                                              />
                                            </td>
                                            <td
                                              style={{
                                                ...styles.label,
                                                paddingBottom: "10px",
                                                textAlign: "center",
                                                width: "25%", // Set width to 25%
                                              }}
                                            >
                                              {file &&
                                              (typeof file[key] === "string" ||
                                                typeof file[key] ===
                                                  "number") &&
                                              file[key] !== "" ? (
                                                <>
                                                  {file[key]}
                                                  {!constKey.includes(key) && (
                                                    <IconButton
                                                      aria-label="content copy"
                                                      size="small"
                                                      onClick={() =>
                                                        handleInputChange(
                                                          key,
                                                          file[key]
                                                        )
                                                      }
                                                    >
                                                      <ContentCopyIcon fontSize="inherit" />
                                                    </IconButton>
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                ...styles.label,
                                                paddingBottom: "10px",
                                                textAlign: "center",
                                                color: "grey",
                                                width: "25%", // Set width to 25%
                                              }}
                                            >
                                              {refrence[key] === ""
                                                ? "-"
                                                : refrence[key]}
                                              {refrence[key] !== "" &&
                                                !constKey.includes(key) && (
                                                  <IconButton
                                                    aria-label="content copy"
                                                    size="small"
                                                    onClick={() =>
                                                      handleInputChange(
                                                        key,
                                                        refrence[key]
                                                      )
                                                    }
                                                  >
                                                    <ContentCopyIcon fontSize="inherit" />
                                                  </IconButton>
                                                )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </form>
                          </div>
                        ))}
                  </div>
                )}
              </ThemeProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateScriptData;
