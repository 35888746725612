export interface DistributionReport {
  _id: string;
  Fund_Name: string;
  distribution_date: string;
  distribution_capitalCost: Number;
  distribution_gainLoss: Number;
  distribution_other: Number;
  distribution_distofCapital: Number;
  distribution_interest: Number;
  distribution_dividends: Number;
  distribution_total: Number;
  fund_ID: string;
  company_ID: string;
  distribution_ID: string;
  portfolio_companyName: string;

}
export const fieldNames: { [key in keyof DistributionReport]: string } = {
  _id: "ID",
  distribution_date: "Distribution Date",
  distribution_capitalCost: "Distribution Capital Cost",
  distribution_gainLoss: "Distribution Gain Loss",
  Fund_Name: "Fund Name",
  fund_ID: "Fund ID",
  distribution_ID: "Distribution ID",
  distribution_other: "Distribution Other",
  distribution_distofCapital: "Distribution Dist of Capital",
  distribution_interest: "Distribution Interest",
  distribution_dividends: "Distribution Dividends",
  distribution_total: "Distribution Total",
  company_ID: "Company ID",
  portfolio_companyName: "Portfolio Company Name",

};
export const orderArray: (keyof DistributionReport)[] = [
  "_id",
  "Fund_Name",
  "distribution_date",
  "distribution_capitalCost",
  "fund_ID",
  "distribution_ID",
  "distribution_gainLoss",
  "distribution_other",
  "distribution_distofCapital",
  "distribution_interest",
  "distribution_dividends",
  "distribution_total",
  "company_ID",
  "portfolio_companyName",

];
