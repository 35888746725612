import React, { CSSProperties, ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Button,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { convertStringToDate } from "../../../../utils/dateUtil";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const form = React.createRef<HTMLFormElement>();

function DistributionCallAIUpdate() {
  const [message, setMessage] = useState("");
  const [date, setDate] = useState<Date | null>(new Date());
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>("");
  const [selectedFundName, setSelectedFundName] = useState<string>("");
  const [inputvalue, setInputValue] = useState<any>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const [extractedData, setExtractedData] = useState<any[]>([]);
  const [filename, setFileName] = useState<FundName[]>([]);
  const [portfolioList, setPortfolioList] = useState<any>([]);
  const [selectedcompanyID, setSelectedCompanyID] = useState<string>("");
  const [selectedcompanyName, setSelectedCompanyName] = useState<string>("");

  interface FundName {
    fund_ID: string;
    Fund_Name: string;
    cc_Date: string;
  }

  interface FormDataObject {
    [fieldName: string]: string;
  }
  useEffect(() => {
    document.title = "AI Extracted Distribution Call Data";
    getFileNames();
  }, []);
  const updateFileData = async () => {
    const form = new FormData();
    let formData2: FormDataObject = inputvalue;
    const midDate = date?.toISOString().split("T")[0];
    const dat = midDate?.split("-").reverse().join("-");
    try {
      if (isUpdate) {
        form.append("formData", JSON.stringify(formData2));
        form.append("company_ID", selectedcompanyID);
        form.append("distribution_date", dat || "");
        const response = await axiosInstance.patch(
          "/updateAIDistributionByID",
          form,
          {}
        );
        if (response.data === "error") {
          setMessage("Error while updating Data.");
        } else {
          setIsSelected(false);
          setMessage("Data updated successfully");
        }
      } else {
        formData2.company_ID = selectedcompanyID;
        formData2.distribution_date = dat || "";
        formData2.distribution_ID = "";
        form.append("formData", JSON.stringify(formData2));
        const response = await axiosInstance.post("/addAIDistributionData", form);
        if (response.data === "error") {
          setMessage("Error while updating Data.");
        } else {
          setIsSelected(false);
          // setFieldData({});
          setMessage("Data updated successfully");
        }
      }
    } catch (err) {
      setMessage("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        if (date) {
          setMessage("");
          getData(selectedcompanyID, date);
          getRefData(selectedcompanyID);
          getcurrentData(selectedcompanyID);
        }
      }, 3000);
    }
  };

  const getData = async (id: string, date: Date) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/extractedDistributionData/" + dat + "/" + id
      );
      let newData = response.data.data[0];
      setExtractedData(newData);
    } catch (error) {
      console.error(error);
    }
  };
  const getRefData = async (fundName: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/getDistributionByID/" + dat + "/" + fundName
      );
      if (response.data.data.length === 0) {
        setMessage("No data found for this company");
      } else setMessage("");
      const responseData = await response.data.data;
      response.data.data[0] != null
        ? setIsSelected(true)
        : setIsSelected(false);
      setStaticData(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  const getcurrentData = async (id: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/getDistributionCurrentData/" + dat + "/" + id
      );
      const responseData = await response.data.data;
      response.data.data[0] != null
        ? setInputValue(responseData[0])
        : setInputValue({
            Fund_Name: selectedFundName,
            distribution_date: dat,
            distribution_capitalCost: "",
            distribution_gainLoss: "",
            distribution_other: "",
            distribution_distofCapital: "",
            distribution_interest: "",
            distribution_dividends: "",
            distribution_total: "",
            fund_ID: selectedFundId,
            company_ID: selectedcompanyID,
            portfolio_companyName: selectedcompanyName,
          });

      if (response.data.data.length === 0) {
        // setInputValue({});
        setIsUpdate(false);
      } else {
        // setInputValue(responseData[0]);
        setIsUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFileNames = async () => {
    try {
      const response = await axiosInstance.get("/extractedDistributionName");
      const responseData = await response.data.data;
      setFileName(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  const getPortfolioCompany = async (id: string) => {
    try {
      const response = await axiosInstance.get(
        "/extractedDistributionCompanies/" + id
      );
      const responseData = await response.data.data;
      setPortfolioList(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  const copyToClipboardAndPaste = (key: any, value: any) => {
    setInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };
  const keyOrder = [""];
  const constKey = [
    "Fund_Name",
    "distribution_date",
    "cc_limitedPartner",
    "cc_ID",
  ];
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };
  const [staticData, setStaticData]: any = useState([
    {
      _id: "",
      Fund_Name: "",
      distribution_date: "",
      distribution_capitalCost: 0,
      distribution_gainLoss: 0,
      distribution_other: 0,
      distribution_distofCapital: 0,
      distribution_interest: 0,
      distribution_dividends: 0,
      distribution_total: 0,
      fund_ID: "",
      company_ID: "",
      distribution_ID: "",
      portfolio_companyName: "",
      distribution_dateKey: "",
    },
  ]);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        AI Extracted Data
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/workFlow">
          workFlow
        </Link>
        <Typography>Distribution Call update</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div style={{ display: "flex" }}>
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Select Fund :
                </label>
                <select
                  style={{
                    backgroundColor: "#f2f2f2",
                    marginRight: "20px",
                    color: "#333",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    width: "250px",
                    cursor: "pointer",
                  }}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const selectedIndex = e.target.selectedIndex - 1;
                    if (selectedIndex >= 0) {
                      const selectedFund = filename.find(
                        (fund) => fund.fund_ID === e.target.value
                      );
                      getPortfolioCompany(e.target.value);

                      setSelectedFundName(selectedFund?.Fund_Name || "");
                    } else {
                      setSelectedFundId("");
                    }
                  }}
                >
                  <option value="">please Select Fund</option>
                  {filename.map((fileName, index) => {
                    return (
                      <option
                        key={filename[index].Fund_Name}
                        value={filename[index].fund_ID}
                        data-fund-name={filename[index].fund_ID}
                      >
                        {filename[index].Fund_Name}
                      </option>
                    );
                  })}
                </select>
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Select Portfolio :
                </label>
                <select
                  style={{
                    backgroundColor: "#f2f2f2",
                    marginRight: "20px",
                    color: "#333",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    width: "250px",
                    cursor: "pointer",
                  }}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const selectedIndex = e.target.selectedIndex - 1;
                    if (selectedIndex >= 0) {
                      setSelectedCompanyID(e.target.value);
                      const date = convertStringToDate(
                        portfolioList[selectedIndex].distribution_date
                      );
                      setSelectedCompanyName(
                        portfolioList[selectedIndex].portfolio_companyName
                      );
                      setDate(date);
                    } else {
                      setSelectedCompanyID("");
                    }
                  }}
                >
                  <option value="">please Select Comapny</option>
                  {portfolioList.map((fileName: any, index: any) => {
                    return (
                      <option
                        key={portfolioList[index].company_ID}
                        value={portfolioList[index].company_ID}
                      >
                        {portfolioList[index].portfolio_companyName}
                      </option>
                    );
                  })}
                </select>

                <Button
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  style={{
                    marginLeft: "auto", // This will push the button to the end
                    padding: "12px 24px",
                    backgroundColor: "#007bff",
                    borderRadius: "24px"
                  }}
                >
                  Back
                </Button>
              </div>
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <DatePicker
                  label="Reporting Date"
                  format="dd/MM/yyyy"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                />

                <button
                  onClick={() => {
                    if (date && selectedcompanyID) {
                      // setInputValue({});
                      setIsSelected(false);
                      getData(selectedcompanyID, date);
                      getRefData(selectedcompanyID);
                      getcurrentData(selectedcompanyID);
                    } else {
                      alert("Please select Fund and date");
                    }
                  }}
                  type="submit"
                  style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    padding: "12px 24px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "24px",
                    cursor: "pointer",
                  }}
                >
                  Get data
                </button>
              </div>
              {isSelected && (
                <div>
                  <div style={styles.buttonContainer}>
                    <button
                      onClick={() => {
                        updateFileData();
                      }}
                      type="button"
                      style={styles.button}
                    >
                      {isUpdate ? "Update" : "Add"}
                    </button>
                  </div>
                  <form
                    ref={form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div>
                      <table
                        style={{
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                ...styles.tableLabel,
                                width: "15%", // Set width to 25%
                              }}
                            >
                              Key name
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "35%", // Set width to 25%
                              }}
                            >
                              Update value manually
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "25%", // Set width to 25%
                              }}
                            >
                              AI extracted value (Page No.)
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                color: "grey",
                                textAlign: "center",
                                width: "25%", // Set width to 25%
                              }}
                            >
                              Previous Entry
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(staticData[0])
                            .filter(
                              (key) =>
                                key !== "_id" &&
                                key !== "run_id" &&
                                key !== "record_type" &&
                                key !== "model_name" &&
                                key !== "fund_ID" &&
                                key !== "created_At" &&
                                key !== "company_ID" &&
                                key !== "portfolio_companyName" &&
                                key !== "distribution_dateKey" &&
                                key !== "distribution_ID" &&
                                !key.includes("pageNumber")
                            )
                            .sort(
                              (a, b) =>
                                keyOrder.indexOf(a) - keyOrder.indexOf(b)
                            )
                            .map((key, index) => {
                              const file = extractedData as {
                                [key: string]: any;
                              };
                              const refrence = staticData[0] as {
                                [key: string]: any;
                              };
                              if (key === "_id") return null;
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    paddingTop: "30px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  <td
                                    style={{
                                      ...styles.label,
                                      paddingBottom: "10px",
                                      width: "15%",
                                    }}
                                  >
                                    {key.replace(/_/g, " ")}
                                  </td>
                                  <td>
                                    <TextField
                                      multiline
                                      id={index.toString()}
                                      name={key}
                                      label={key.replace(/_/g, " ")}
                                      style={{
                                        width: "100%", // Make input take full width of its cell
                                        textAlign: "justify",
                                        marginBottom: "10px",
                                        background: "#fff", // Adds a white background
                                        borderColor: "#007bff", // Blue border color
                                        borderWidth: "2px", // Border width
                                        borderRadius: "4px", // Rounded corners
                                        padding: "5px", // Internal padding
                                      }}
                                      value={
                                        inputvalue != null
                                          ? inputvalue[key]
                                          : ""
                                      }
                                      type="text"
                                      onChange={(e) => {
                                        setInputValue(
                                          (prevInputValue: any) => ({
                                            ...prevInputValue,
                                            [key]: e.target.value,
                                          })
                                        );
                                      }}
                                      disabled={constKey.includes(key)}
                                    />
                                  </td>
                                  <td
                                    style={{
                                      ...styles.label,
                                      textAlign: "center",
                                      paddingBottom: "10px",
                                      width: "25%", // Set width to 25%
                                    }}
                                  >
                                    {file && file[key] ? file[key] : ""}
                                    {file &&
                                      file[key] !== "" &&
                                      !constKey.includes(key) && (
                                        <IconButton
                                          aria-label="content copy"
                                          size="small"
                                          onClick={() =>
                                            copyToClipboardAndPaste(
                                              key,
                                              file[key]
                                            )
                                          }
                                        >
                                          <ContentCopyIcon fontSize="inherit" />
                                        </IconButton>
                                      )}
                                  </td>
                                  <td
                                    style={{
                                      ...styles.label,
                                      textAlign: "center",
                                      color: "grey",
                                      paddingBottom: "10px",
                                      width: "25%", // Set width to 25%
                                    }}
                                  >
                                    {refrence[key]}
                                    {refrence[key] !== "" &&
                                      !constKey.includes(key) && (
                                        <IconButton
                                          aria-label="content copy"
                                          size="small"
                                          onClick={() =>
                                            copyToClipboardAndPaste(
                                              key,
                                              refrence[key]
                                            )
                                          }
                                        >
                                          <ContentCopyIcon fontSize="inherit" />
                                        </IconButton>
                                      )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              )}

              {message !== "" && (
                <Typography mt={10} variant="subtitle2" color="textPrimary">
                  {message}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DistributionCallAIUpdate;
