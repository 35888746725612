import React, {
  useState,
  ChangeEvent,
  FormEvent,
  CSSProperties,
  useEffect,
} from "react";
import { Grid, TextField } from "@mui/material";

interface DynamicFormProps {
  fieldData: {
    [fieldName: string]: {
      type: string;
      defaultValue: string;
    };
  };
  changedata: (
    formData: { [fieldName: string]: string },
    updateType: "add" | "update"
  ) => void;
  staticData: boolean;
  newData: boolean;
  reportingDate: string;
}

interface FormData {
  [fieldName: string]: string;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  fieldData,
  changedata,
  staticData,
  newData,
  reportingDate,
}) => {
  const [image, setImage] = useState("");
  useEffect(() => {
    if (staticData) {
      const updatedFormState: FormData = {};
      setImageError(false);
      Object.keys(fieldData).forEach((fieldName) => {
        if (fieldName === "Reporting_Date") {
          const [day, month, year] =
            fieldData[fieldName].defaultValue.split("-");
          const formattedDate = `${year}-${month}-${day}`;
          updatedFormState[fieldName] = formattedDate;
        } else {
          updatedFormState[fieldName] = fieldData[fieldName].defaultValue;
        }
      });
      setFormData(updatedFormState);
    } else {
      const updatedFormState: FormData = {};
      Object.keys(fieldData).forEach((fieldName) => {
        if (fieldName === "Reporting_Date") {
          const [day, month, year] = reportingDate.split("-");
          const formattedDate = `${day}-${month}-${year}`;
          updatedFormState[fieldName] = formattedDate;
        } else {
          updatedFormState[fieldName] = "";
        }
      });
      setFormData(updatedFormState);
    }
  }, [fieldData, reportingDate, staticData]);
  const initialFormState: FormData = {};
  if (staticData) {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = fieldData[fieldName].defaultValue || "";
    });
  } else {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = "";
    });
  }
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setImage(objectURL);
    }
    if (file) {
      setFormData({
        ...formData,
        [event.target.name]: file, // Store the File object
      });
    }
  };
  const [imageError, setImageError] = useState(false);

  // Function to handle image load error
  const handleImageError = () => {
    setImageError(true);
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [changedFields, setChangedFields] = useState<FormData>({});
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      paddingBottom: "10px",
      width: "100%", // Align the text to the right within the label cell
      verticalAlign: "top",
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      verticalAlign: "text-top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "24px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "24px",
      cursor: "pointer",
    },
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setChangedFields({
      ...changedFields,
      [name]: value,
    });
  };
  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Call the onSubmit prop with the formData
    changedata(formData, "add");
    setChangedFields({});
  };
  const handleSubmitForm2 = () => {
    if (formData["portfolio_companyLogo"] != null) {
      const data = {
        portfolio_companyLogo: formData["portfolio_companyLogo"],
        ...changedFields,
      };
      changedata(data, "update");
      setChangedFields({});
    } else if (formData["fund_logo"] != null) {
      const data = {
        fund_logo: formData["fund_logo"],
        ...changedFields,
      };
      changedata(data, "update");
      setChangedFields({});
    } else {
      changedata(changedFields, "update");
      setChangedFields({});
    }
  };
  const form = React.createRef<HTMLFormElement>();
  const restrictedKeys = [
    "pdf_link",
    "company_ID",
    "fund_ID",
    "portfolio_geoLocation",
    "ESG_Report",
    "fund_news",
    "fund_geoLocation",
    "AQ_Score ",
    "portfolio_news",
    "demoData",
  ];
  const fundKeyNames: { [key: string]: string } = {
    fund_logo: "Logo",
    fund_strategy: "Description",
    fund_teamSize: "Team Size",
    fund_highlights: "Highlights",
    fund_websiteURL: "Website URL",
    fund_focusCountry: "Focus Country",
    fund_historicalTer: "Historical TER",
    fund_ConcLim: "Concentration Limit",
    fund_Basis: "Basis",
    fund_Recycling: "Recycling",
    AQ_Score: "AQ Score",
    fund_called: "Fund Called",
    fund_teamBackground: "Team Background",
    fund_capitalInvested: "Capital Invested",
    Textboxes_QtrlyUpdate: "Qtrly Update",
    fund_Pref: "Preferred Return",
    fund_Carry: "Carry",
    fund_Textboxes_QtrlyreportUpdate: "Textbox Qtrly Update",
    fund_corporateActions: "Corporate Actions",
    fund_gpUpdates: "GP Updates",
    fund_riskDisclosure: "Risk Disclosure",
    fund_extraOrdinaryMovements: "Extraordinary Movements",
    fund_macroInductryDrivers: "Macro Industry Drivers",
    fund_keyDriversPreviousQTR: "Key Drivers Previous QTR",
    fund_financialSummary: "Financial Summary",
    fund_totalOtherAssetsLiabilities: "Total Other Assets Liabilities",
    fund_rvpi: "RVPI",
    Textboxes_ToBeRealized: "To Be Realized",
    AUC_GPDist: "AUC GP Distribution",
    AUC_GPNav: "AUC GP NAV",
    AUC_Reserves: "AUC Reserves",
    fund_partners: "Partners",
    fund_nav: "Net Asset Value",
    fund_dpi: "DPI",
    fund_tvpi: "TVPI",
    fund_capitalCalled: "Capital Called",
    fund_recallableDistributions: "Recallable Distributions",
    fund_netIRR: "Net IRR",
    fund_gpCarriedDistribution: "GP Carried Distribution",
    fund_distributed: "Fund Distributed",
    fund_IRR: "IRR",
    fund_currency: "Currency",
    fund_Fees: "Fund Fees",
    fund_Life_LPA: "Fund Life",
    Fund_Name: "Fund Name",
    fund_generalPartner: "General Partner",
    fund_investmentAdvisor: "Investment Advisor",
    fund_investmentManager: "Investment Manager",
    fund_fundSizeFinalClose: "Fund Size (Final Close)",
    fund_FirstClosing: "First Closing Date",
    fund_Life_Exp: "Fund Life expected",
    fund_commitment: "FoF Commitment",
    fund_gpCommitment: "GP Commitment",
    fund_legalStructure: "Legal Structure",
    fund_carriedInterest: "GP carried interest",
    fund_hurdleRate: "Hurdle rate",
    fund_clawBack: "GP Clawback",
    fund_removalWithCause: "Removal with Cause",
    fund_removalWithoutCause: "Removal without Cause",
    fund_treatmentOfCarreidInterestOnRemoval:
      "Treatment of Carried Interest on Removal",
    fund_overview: "Fund Overview",
    fund_botURL: "Fund Bot URL",
    lp_investmentStake: "LP Stake",
    LP_name: "LP Name",
    fund_active: "Active",
    fund_InvPer: "Investment Period",
    fund_PostInv: "Post Investment",
    fund_keyPersonProvision: "Key Person Provision",
    fund_governingLawAndJurisdiction: "Governing Law and Jurisdiction",
    fund_stage: "Fund Stage",
    fund_region: "Fund Region",
    Reporting_Date: "Reporting Date",
    fund_sectorFocus: "Sector Focus",
    fund_vintage: "Vintage",
    FTLA_InvPer: "Investment Period",
    fund_valueNetAsset: "Fund Net Asset Value",
    fund_expCalledRecalled: "Capital Called/Recalled",
    fund_percentageFundSizeLPA: "Percentage of Fund Size to LPA",
    fund_percentageNICLPA: "Net Invested Capital to LPA",
    fund_InvPer_ExpAct: "Investment Period vs Expected Actual",
    fund_endDate: "End Of Life",
    fund_ExpCarry: "Expected Carry",
    fund_numberOfInvestmentProjected: "Investments Projected",
    fund_capitalAvailable: "Capital Available",
    fund_recalledAmount: "Recalled Amount",
    AUC_IRR: "AUC IRR",
    fund_ficialSummary: "ficial Summary",
    fund_capitalReserved: "Capital Reserved",
    fund_valueDistributed: "Value Distributed",
    fund_numberOfInvestmentToDate: "Number of Investments to Date",
    fund_PartnerExp_toDate: "Partner Expenses to Date",
    fund_OrgFees_toDate: "Organizational Fees to Date",
    fund_MgmtFees_toDate: "Management Fees to Date",
    fund_treatmentOfCarriedInterestOnRemoval: "Carried Interest on Removal",
    Textboxes_Strategy: "Fund description",
    Textboxes_Outlook: "Fund Outlook",
    Textboxes_FundPipe: "Fund Investment Pipeline",
    "Textboxes_Co-InvestPipe": "Fund Co-Investment Pipeline",
    Textboxes_Exits: "Fund Exits",
    fund_netAssetValueGPCarried: "Net Asset Value GP Carried",
    fund_uncalledCapital: "Uncalled Capital",
    Textboxes_Overview: "Portfolio Qtrly Update",
    Main_Company_Name: "Clean Company Name",
    portfolio_proceeds: "Proceeds",
    portfolio_fmv: "FMV",
    portfolio_arr: "ARR",
    portfolio_ebit: "EBIT",
    portfolio_pbt: "PBT",
    portfolio_GP_Lead: "GP Lead",
    portfolio_yearOfInvestment: "Year of Investment",
    fund_commitmentPeriodEndDate: "Commitment Period End Date",
    PublicMkt_Status: "Public Market Status",
    portfolio_companyName: "Company Name",
    PublicMkt_Exchange: "Public Market Exchange",
    PublicMkt_Ticker: "Public Market Ticker",
    portfolio_companyState: "Company State",
    portfolio_StatusDate: "Status Date",
    portfolio_companyLogo: "Logo",
    portfolio_companyURL: "URL",
    portfolio_companyOverview: "Overview",
    portfolio_companyCountry: "Country",
    portfolio_firstInvestmentDate: "First Investment Date",
    portfolio_exitDate: "Exit Date",
    Previous_Name: "Previous Name",
    InvCostoDate_Dilutive: "Investment Cost to Date Dilutive",
    InvCostoDate_NonDilutive: "Investment Cost to Date Non Dilutive",
    Previous_Name_2: "Past other name",
    portfolio_realizedGainLoss: "Realized Investment Gain/Loss",
    portfolio_unrealizedCost: "Unrealized Investment Cost",
    portfolio_unrealizedNAV: "Unrealized Investment Net Asset Value",
    portfolio_currency: "Currency",
    portfolio_FiscalYearEnd: "Fiscal Year End",
    positive_impact: "Positive Impact",
    portfolio_realizedValue: "Realized Fund",
    portfolio_geoLocation: "Location coordinates",
    portfolio_updatedAt: "Updated At",

    portfolio_companyCountries: "Company Countries",
    portfolio_companyOverviews: "Company Description",
    portfolio_companyStates: "Company States",
    portfolio_sector: "Sector",
    Textboxes_QtrUpdate: "Qtrly Update",
    portfolio_revenue: "Revenue",
    portfolio_CapitalInvested_NonDil: "Capital Invested Non Dilutive",
    portfolio_reservedCapital: "Reserved Capital",
    portfolio_moicFinal: "MOIC Final",
    portfolio_unrealizedNAV_NonDil: "Unrealized NAV Non Dilutive",
    portfolio_initialInvt_NonDil: "Initial Investment Non Dilutive",
    portfolio_initialInvt_Dilutive: "Initial Investment Dilutive",
    portfolio_capitalInvested: "Capital Invested",
    portfolio_capitalReserved: "Capital Reserved",
    portfolio_ROI: "ROI",
    portfolio_unrealizedGainLoss: "Unrealized Gain/Loss",
    portfolio_totalGainLoss: "Total Gain/Loss",
    portfolio_moc: "MOC",
    portfolio_ebitda: "EBITDA",
    portfolio_initialInvt_FDO: "Fully Diluted Ownership",
    portfolio_latestNav: "Latest NAV",
    portfolio_tvpi: "Portfolio TVPI",
    portfolio_holdingPeriod: "Holding Period",
    portfolio_totalCumulativeValue: "Total Cumulative Value",
    portfolio_keyShareholders: "Key Shareholders",
    portfolio_grossMargin: "Gross Margin",
    PublicMkt_SharePrice: "Public Market Share Price",
    portfolio_realizedCost: "Realized Investment Cost",
  };
  if (!staticData) {
    return (
      <div
        style={{
          marginTop: "25px",
        }}
      >
        <form onSubmit={handleSubmitForm} ref={form}>
          <table>
            <tbody>
              {Object.keys(fieldData)
                .filter(
                  (key) =>
                    key !== "pdf_link" &&
                    key !== "fund_ID" &&
                    key !== "company_ID"
                )
                .map((fieldName, index, array) => {
                  if (
                    fieldName === "fund_logo" ||
                    fieldName === "portfolio_companyLogo"
                  ) {
                    return (
                      <tr
                        key={fieldName}
                        style={{
                          paddingTop: "30px",
                          borderBottom:
                            index !== array.length - 1
                              ? "1px solid #cccccc"
                              : "none",
                        }}
                      >
                        <td style={styles.label || { verticalAlign: "top" }}>
                          {fieldName}
                        </td>
                        <td>
                          <input
                            key={fieldName}
                            type="file"
                            id={fieldName}
                            name={fieldName}
                            onChange={handleFileChange} // Separate handler for file type
                          />
                        </td>
                        <>
                          {image === "" ? (
                            fieldData[fieldName].defaultValue && !imageError ? (
                              <img
                                src={
                                  fieldData[fieldName].defaultValue as string
                                }
                                width="100px"
                                height="100px"
                                onError={handleImageError}
                                alt="Dynamic Content"
                              />
                            ) : (
                              <p>No image available</p>
                            )
                          ) : (
                            <img
                              src={image as string}
                              width="100px"
                              height="100px"
                              onError={handleImageError}
                              alt="Dynamic Content"
                            />
                          )}
                        </>
                      </tr>
                    );
                  } else {
                    return (
                      <tr
                        key={fieldName}
                        style={{
                          paddingTop: "30px",
                          borderBottom:
                            index !== array.length - 1
                              ? "1px solid #cccccc"
                              : "none",
                        }}
                      >
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 20px",
                            width: "20%",
                          }}
                        >
                          {fundKeyNames[fieldName] || fieldName}
                        </td>
                        <td style={{ verticalAlign: "top", width: "25%" }}>
                          <TextField
                            multiline
                            style={{
                              width: "100%",
                              background: "#fff",
                              borderColor: "#007bff",
                              borderWidth: "1px",
                              borderRadius: "4px", // Rounded corners
                              padding: "5px", // Internal padding
                            }}
                            minRows={1}
                            maxRows={7}
                            type={fieldData[fieldName].type}
                            id={fieldName}
                            name={fieldName}
                            value={formData[fieldName] || ""}
                            onInput={handleChange}
                            onChange={handleChange}
                            disabled={fieldName === "Reporting_Date"}
                          />
                        </td>
                        <td style={styles.label2} width={"100%"}>
                          {fieldData[fieldName].defaultValue}
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.button}>
              {newData ? "Add" : "Update"}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div
        style={{
          marginTop: "25px",
        }}
      >
        <form onSubmit={handleSubmitForm} ref={form}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {Object.keys(fieldData)
              .filter((key) => !restrictedKeys.includes(key))
              .map((fieldName) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  key={fieldName}
                  style={{ paddingTop: "30px" }}
                >
                  <div style={styles.label}>
                    {fundKeyNames[fieldName] || fieldName}
                  </div>
                  {fieldData[fieldName].type === "file" ? (
                    <>
                      <input
                        key={fieldName}
                        type="file"
                        id={fieldName}
                        name={fieldName}
                        onChange={handleFileChange} // Separate handler for file type
                      />
                      <>
                        {fieldData[fieldName].defaultValue && !imageError ? (
                          <img
                            src={fieldData[fieldName].defaultValue as string}
                            width="100px"
                            height="100px"
                            onError={handleImageError}
                            alt="Dynamic Content"
                          />
                        ) : (
                          <p>No image available</p>
                        )}
                      </>
                    </>
                  ) : (
                    <>
                      <TextField
                        minRows={1}
                        maxRows={5}
                        style={{
                          width: "100%",
                          background: "#fff",
                          borderColor: "#007bff",
                          borderWidth: "1px",
                          borderRadius: "4px", // Rounded corners
                          padding: "5px", // Internal padding
                        }}
                        multiline={fieldData[fieldName].type === "text"}
                        type={
                          fieldData[fieldName].type === "number"
                            ? "number"
                            : "text"
                        }
                        id={fieldName}
                        name={fieldName}
                        value={formData[fieldName] || ""}
                        onChange={handleChange}
                        // label={fieldName}

                        disabled={
                          fieldName === "LP_name" ||
                          fieldName === "Reporting_Date"
                        }
                      />
                    </>
                  )}
                </Grid>
              ))}
          </Grid>
        </form>
        <div style={styles.buttonContainer}>
          <button
            type="button"
            style={styles.button2}
            onClick={handleSubmitForm2}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
};

export default DynamicForm;
