export const WorkflowSVG = () => {

    return (
        <>
            <svg enableBackground="new 0 0 64 64" id="Layer_1" version="1.1" viewBox="0 0 64 64" xmlSpace="preserve"
                 xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <path d="  M32,11.1c11.6,0,20.9,9.4,20.9,21S43.6,53,32,53H7" fill="none" stroke="#EEEEEE"
                      strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5"/>
                <path d="  M19.9,49.2C14.5,45.4,11,39.1,11,32c0-9.5,6.4-17.6,15.1-20.1" fill="none" stroke="#EEEEEE"
                      strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5"/>
                <line fill="none" stroke="#EEEEEE" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                      strokeWidth="5" x1="44" x2="58" y1="53" y2="53"/>
                <line fill="none" stroke="#EEEEEE" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                      strokeWidth="5" x1="58.7" x2="55.1" y1="52.9" y2="49.4"/>
                <line fill="none" stroke="#EEEEEE" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                      strokeWidth="5" x1="58.7" x2="55.4" y1="52.9" y2="56.2"/>
                <line fill="none" stroke="#EEEEEE" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                      strokeWidth="5" x1="31.1" x2="34.7" y1="10.7" y2="14.2"/>
                <line fill="none" stroke="#EEEEEE" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                      strokeWidth="5" x1="31.1" x2="34.4" y1="10.7" y2="7.4"/>
            </svg>
        </>
    );
}

export const AddDocumentSVG = () => {
    return (
        <svg height="32" id="icon" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
            <polygon points="30 24 26 24 26 20 24 20 24 24 20 24 20 26 24 26 24 30 26 30 26 26 30 26 30 24"/>
            <path
                stroke="#EEEEEE"
                d="M16,28H8V4h8v6a2.0058,2.0058,0,0,0,2,2h6v4h2V10a.9092.9092,0,0,0-.3-.7l-7-7A.9087.9087,0,0,0,18,2H8A2.0058,2.0058,0,0,0,6,4V28a2.0058,2.0058,0,0,0,2,2h8ZM18,4.4,23.6,10H18Z"/>
            <rect style={{fill: "none"}} data-name="&lt;Transparent Rectangle&gt;" height="32"
                  id="_Transparent_Rectangle_"
                  stroke="#EEEEEE"
                  width="32"/>
        </svg>
    )
}

export const ExcelSheetSVG = () => {
    return (
        <svg enableBackground="new 0 0 512 512" height="100%" id="Layer_1" version="1.1" viewBox="0 0 512 512"
             width="100%" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <path stroke="#111" d="M468,117.499L357.944,8H115.581C98.792,8,85,20.858,85,37.646v156.6c0-0.03,1.023-0.246,1.703-0.246H100  V37.646C100,29.265,107.2,23,115.581,23h235.583L356,27.535v61.593C356,105.847,369.596,119,386.315,119h61.593l4.092,4.795v349.553  c0,8.381-5.822,15.652-14.203,15.652H115.581C107.2,489,100,481.728,100,473.348V413H86.703c-0.68,0-1.703,0.037-1.703,0.007v60.34  C85,490.136,98.792,504,115.581,504h322.217C454.586,504,468,490.136,468,473.348V117.499z M386.315,104  C377.934,104,371,97.509,371,89.128V44.232v-0.291L431.502,104H386.315z" fill="#111"/>
            <path
                d="M373.602,209H74.398C57.61,209,44,222.61,44,239.398v127.204C44,383.39,57.61,397,74.398,397h299.204  C390.39,397,404,383.39,404,366.602V239.398C404,222.61,390.39,209,373.602,209z M164.046,353l-18.785-30.122L126.673,353H97.21  l31.968-49.12L99.122,257h28.87l17.401,29.793L162.135,257h29.727l-30.518,48.923L194.168,353H164.046z M268,353h-63v-96h26v75h37  V353z M308.495,287.563c1.955,1.494,7.261,4.218,15.918,8.173c8.306,3.735,14.073,7.745,17.303,12.029s4.845,9.679,4.845,16.182  c0,5.977-1.517,11.282-4.548,15.918c-3.032,4.637-7.405,8.239-13.117,10.81c-5.713,2.571-12.414,3.856-20.104,3.856  c-6.416,0-11.832-0.451-16.181-1.352c-4.351-0.899-8.611-2.472-13.611-4.713v-23.201c5,2.549,10.161,4.538,15.522,5.965  c5.36,1.429,10.299,2.143,14.781,2.143c3.866,0,6.708-0.67,8.511-2.011c1.801-1.34,2.706-3.064,2.706-5.174  c0-1.318-0.36-2.472-1.086-3.461c-0.725-0.988-1.889-1.987-3.492-2.999c-1.604-1.01-5.876-3.075-12.82-6.195  c-6.284-2.856-10.997-5.624-14.137-8.306c-3.143-2.68-5.471-5.756-6.987-9.228c-1.516-3.471-2.273-7.58-2.273-12.326  c0-8.875,3.23-15.796,9.689-20.763c6.46-4.965,15.336-7.448,26.629-7.448c9.975,0,20.147,2.307,30.519,6.92l-7.976,20.104  c-9.009-4.13-16.786-6.196-23.334-6.196c-3.384,0-5.844,0.593-7.382,1.779c-1.539,1.187-2.307,2.659-2.307,4.417  C305.562,284.378,306.54,286.069,308.495,287.563z"
                fill="#111" stroke="#111"/></svg>
    );
}

export const MultipleFileSVG = () => {
    return (
        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <rect fill="none" height="256" width="256"/>
            <path d="M168,224H56a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8h80l40,40V216A8,8,0,0,1,168,224Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <path d="M80,64V40a8,8,0,0,1,8-8h80l40,40V184a8,8,0,0,1-8,8H176" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
            <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="88" x2="136" y1="152" y2="152"/>
            <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" x1="88" x2="136" y1="184" y2="184"/>
        </svg>
    );
}

export const OnboardingSVG = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             width="64px" height="64px" viewBox="0 0 64 64"
             xmlSpace="preserve">

            <path style={{fill:"none",stroke:"#EEEEEE",strokeWidth:5,strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:"10"}} d="M27.8,45.9c-0.2-1.6-1.6-2.7-3.2-2.6c-0.3,0-1.6,1.3-1.8,1.4c0.6-0.6,2-2.7,1.9-3.6c-0.2-1.6-1.6-2.7-3.2-2.6
	c-0.3,0-1.7,1.5-2,1.7c0.4-0.6,1.6-2.6,1.5-3.4c-0.2-1.6-1.6-2.7-3.2-2.6c-0.7,0.1-2.2,1.8-2.6,2.3c0,0,0.9-1.5,0.9-1.6
	c-0.2-1.5-1.5-2.6-3.1-2.5c-0.7,0.1-1.4,0.4-1.8,1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.8,1-2,2.6-2,2.6c-0.6,1-0.6,1.7,0,2.9
	c0.7,1.2,3.3,4.6,5.5,6.8c2.2,2.1,6.3,5.4,7.1,5.9s2.2,0.2,2.6-0.3s2.4-2.7,2.9-3.4C27.6,47.5,27.8,46.8,27.8,45.9z"/>
            <path style={{fill:"none",stroke:"#EEEEEE",strokeWidth:5,strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:"10"}}
                  d="M25.4,15.3c0,0-9.4-4.2-11.3-5.1c-1.8-0.9-7.7,6.2-9.5,9.6S2,27.1,3.2,28.1s4.3,3.5,4.3,3.5"/>
            <path style={{fill:"none",stroke:"#EEEEEE",strokeWidth:5,strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:"10"}}
                  d="M28.8,51.9c1.7,1.3,5.4,2.7,7.9-0.2c3.5,1.7,6.2-0.4,6.5-2.9c3.2-0.1,5.2-2,5.2-3.5c2.3-0.4,4.7-1.6,3.8-5.3"/>
            <path style={{fill:"none",stroke:"#EEEEEE",strokeWidth:5,strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:"10"}} d="M21.8,21.1c1.2-0.4,6.6-2.9,8.2-3.8s2.9-1.5,5.7-0.6s8.8,2.3,8.8,2.3s5.4-2.2,7.2-3.1s11.8,14.6,10.1,15.8
	c-1.7,1.2-5.6,4.7-10.6,4.7c-3-2.1-14.7-10.5-15.5-11.3c-0.9-0.7-0.8-1.6-2.3-0.8c-1.6,0.9-6.5,2.8-9.4,1.8
	C21.1,25.1,18.4,22.1,21.8,21.1z"/>
            <line style={{fill:"none",stroke:"#EEEEEE",strokeWidth:5,strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:"10"}} x1="37.1" y1="51.8" x2="32.5" y2="48.3"/>
            <line style={{fill:"none",stroke:"#EEEEEE",strokeWidth:5,strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:"10"}} x1="43.2" y1="48.8" x2="36.9" y2="44.2"/>
            <line style={{fill:"none",stroke:"#EEEEEE",strokeWidth:5,strokeLinecap:"round",strokeLinejoin:"round",strokeMiterlimit:"10"}} x1="48.4" y1="45.2" x2="41.4" y2="40.2"/>
</svg>
    );
}
