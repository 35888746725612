import {
  Grid,
  TextField,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { convertToDateFormat } from "../../../../utils/dateUtil";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AIDistributionCall() {
  const { user } = useAuth0();

  const [fundName, setFundName] = useState("");
  const [portfolioName, setPortfolioName] = useState("");
  const [fundNames, setFundNames] = useState([]);
  const [date, setDate] = useState<Date | null>(new Date());
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [portfolioNames, setPortfolioNames] = useState([]);
  const [data, setData] = useState("");
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const resetData = () => {
    setData("");
    setIsButtonDisabled(false);
    setFile(null);
  };
  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    setFundNames(response.data.data);
    setFundName(response.data.data[0]["fund_ID"]);
    getPortfolioNames(response.data.data[0]["fund_ID"]);
  };
  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;
      setPortfolioNames(responseData);
      setPortfolioName(responseData[0]["company_ID"]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFundChange = (event: any) => {
    setFundName(event.target.value);
  };
  const handleChange = (e: any) => {
    const selectedValue = e.target.value;
    setPortfolioName(selectedValue);
  };

  const runscript = async () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 10000);
    if (file) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      try {
        const formData = new FormData();
        const name = file.name.slice(0, -4);
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
        formData.append("timestamp", time);
        formData.append("fund_ID", fundName);
        formData.append("file_name", name);
        formData.append("email", userEmail as string);
        formData.append("company_ID", portfolioName);
        formData.append("date", convertToDateFormat(date as Date));
        const response = await axiosInstance.post(
          "/AIDistribtionExtraction",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            params: {
              timestamp: time,
              name: name,
            },
          }
        );
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error("No file selected.");
    }
  };
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      <Helmet title="Alerts" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Distribution Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/workFlow">
          WorkFlow
        </Link>
        <Typography>Add Distribution Report</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundName}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundNames.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select Portfolio name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Portfolio</InputLabel>
                <Select
                  value={portfolioName}
                  style={{ maxWidth: "300px" }}
                  onChange={handleChange}
                  label="Portfolio"
                >
                  {portfolioNames.map((portfolio) => (
                    <MenuItem
                      key={portfolio["portfolio_companyName"]}
                      value={portfolio["company_ID"]}
                    >
                      {portfolio["portfolio_companyName"]}
                    </MenuItem>
                  ))}
                  {/* {portfolioNames.map((portfolio, index) => {
                    const portfolioName = Object.keys(portfolio)[0];
                    const company_ID = portfolio[portfolioName];
                    return (
                      <option key={index} value={company_ID}>
                        {portfolioName}
                      </option>
                    );
                  })} */}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                marginLeft: "5px",
              }}
            >
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select reporting Date:
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "1px",
                }}
              >
                <DatePicker
                  label="Reporting Date"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                />
                {/* <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                {/* Year Selector */}
                {/* <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300, // Adjust max height to your preference
                          },
                        },
                      }}
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "50px",
                border: "2px dashed gray",
                padding: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input
            >
              {file ? file.name : "Drag & Drop or Click to Select a File"}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                onClick={(e: any) => (e.target.value = "")}
                ref={inputFileRef} // Attach the ref to the input
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <TextField
                fullWidth
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                type="email"
                label="Email Address"
                variant="outlined"
                placeholder="email@example.com"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                disabled={isButtonDisabled}
                onClick={runscript}
                style={{
                  backgroundColor: isButtonDisabled ? "#9E9E9E" : "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: isButtonDisabled ? "default" : "pointer",
                  outline: "none",
                  marginRight: "10px", // Add some space between the buttons
                }}
              >
                Process
              </button>
              <button
                onClick={resetData}
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "24px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                Reset
              </button>
            </div>

            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "10%",
              }}
            >
              <div style={{ paddingTop: "10px", marginRight: "4%" }}>
                {data}
              </div>
              {data ===
                "Unfortunately we cannot process encrypted files at this time. Please come back in a few weeks for the next release" && (
                <button
                  onClick={resetData}
                  style={{
                    marginLeft: "40%",
                    marginTop: "20px",
                    backgroundColor: "#D32F2F",
                    color: "white",
                    border: "none",
                    padding: "12px 24px",
                    borderRadius: "24px",
                    fontSize: "16px",
                    fontWeight: "500",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIDistributionCall;
