import React, { RefObject } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface PortfolioFormProps {
  form: RefObject<HTMLFormElement>;
  staticValue: Array<Record<string, any>>;
  extractedData: Record<string, any>;
  inputvalue: Record<string, any>;
  inputRefs: React.MutableRefObject<Record<string, HTMLInputElement | null>>;
  handlePortfolioChange: (key: string, value: any) => void;
  constKey: string[];
  keyOrder: string[];
  PortfolioKeyNames: Record<string, string>;
  styles: Record<string, React.CSSProperties>;
}

const PortfolioForm: React.FC<PortfolioFormProps> = ({
  form,
  staticValue,
  extractedData,
  inputvalue,
  inputRefs,
  handlePortfolioChange,
  constKey,
  keyOrder,
  PortfolioKeyNames,
  styles,
}) => {
  const renderRow = (key: string, index: number) => {
    const file = extractedData;
    const reference = staticValue[0];

    if (key === "_id") return null;

    const label = PortfolioKeyNames[key] || key.replace(/_/g, " ");

    return (
      <tr
        key={index}
        style={{ paddingTop: "30px", borderBottom: "1px solid #cccccc" }}
      >
        <td style={{ ...styles.label, paddingBottom: "10px", width: "20%" }}>
          {label}
        </td>
        <td>
          <TextField
            key={inputvalue[key]}
            inputRef={(el) => (inputRefs.current[key] = el)}
            multiline
            id={index.toString()}
            name={key}
            label={label}
            style={{
              width: "100%",
              textAlign: "justify",
              marginBottom: "10px",
              background: "#fff",
              borderColor: "#007bff",
              borderWidth: "2px",
              borderRadius: "4px",
              padding: "5px",
            }}
            value={inputvalue[key]}
            type="text"
            onChange={(e) => handlePortfolioChange(key, e.target.value)}
            disabled={constKey.includes(key)}
          />
        </td>
        <td
          style={{
            ...styles.label,
            textAlign: "center",
            paddingBottom: "10px",
            width: "25%",
          }}
        >
          {file[key] &&
          (typeof file[key] === "string" || typeof file[key] === "number") ? (
            <>
              {file[key]}
              {!constKey.includes(key) && (
                <IconButton
                  aria-label="content copy"
                  size="small"
                  onClick={() => handlePortfolioChange(key, file[key])}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              )}
            </>
          ) : (
            ""
          )}
        </td>
        <td
          style={{
            ...styles.label,
            textAlign: "center",
            color: "grey",
            paddingBottom: "10px",
            width: "25%",
          }}
        >
          {reference[key] === "" ? "-" : reference[key]}
          {reference[key] && !constKey.includes(key) && (
            <IconButton
              aria-label="content copy"
              size="small"
              onClick={() => handlePortfolioChange(key, reference[key])}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          )}
        </td>
      </tr>
    );
  };

  return (
    <form ref={form} onSubmit={(e) => e.preventDefault()}>
      <div>
        <table style={{ textAlign: "left", width: "100%" }}>
          <thead>
            <tr>
              <th style={{ ...styles.tableLabel, width: "20%" }}>Key name</th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "30%",
                }}
              >
                Update value manually
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "25%",
                }}
              >
                AI extracted value
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  color: "grey",
                  textAlign: "center",
                  width: "25%",
                }}
              >
                Previous Entry
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(staticValue[0])
              .filter(
                (key) =>
                  key !== "_id" &&
                  key !== "run_id" &&
                  key !== "created_At" &&
                  key !== "record_type" &&
                  key !== "fund_ID" &&
                  key !== "company_ID" &&
                  !key.includes("pageNumber")
              )
              .sort((a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b))
              .map((key, index) => renderRow(key, index))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default PortfolioForm;
