import { useState } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import { FundQtrProps, IFundQtr } from "../../interfaces/fundQtr.interface";
import { styles } from "./../../Style/fundQtr";
import DynamicForm from "../../pages/pages/Add_updateForm";
import { toast } from "react-toastify";
function FundQtr({ date, id }: FundQtrProps) {
  const [fieldData, setFieldData] = useState({});
  const [newData, setNewData] = useState(false);
  function createFieldData(responseData: IFundQtr): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | Number;
    };
  } {
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | Number;
      };
    } = {};

    Object.keys(responseData).forEach((key) => {
      if (
        key === "_id" ||
        key === "ReportingDateKey" ||
        key === "ReportingDateString"
      ) {
        // Exclude specific fields from being added to fieldData
      } else {
        if (key === "Reporting_Date") {
          // Check if the field name is the date field you want to set as "type: date"
          fieldData[key] = {
            type: "date",
            defaultValue: responseData[key as keyof IFundQtr] as Date,
          };
        } else {
          const value = responseData[key as keyof IFundQtr];
          if (typeof value === "number") {
            fieldData[key] = {
              type: "number",
              defaultValue: value,
            };
          } else {
            fieldData[key] = {
              type: "text",
              defaultValue: value as string | undefined,
            };
          }
        }
      }
    });
    return fieldData;
  }
  const fetchData2 = async () => {
    setUpdateMessage("add");
    setFieldData({});
    setNewData(true);
    try {
      const validation = await axiosInstance.get(
        "/getFundQtr/" + date + "/" + id
      );
      if (validation.data.data.length !== 0) {
        toast.error("Data already exists for the given date please update it");
        return;
      }
      const response = await axiosInstance.get("/fund-qtr-sample/" + date + "/" + id);
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          toast.error("There is no data with the given Date");
          setFieldData({});
        } else {
          const responseData = response.data.data[0]; // Assuming this is your response data
          setFieldData(createFieldData(responseData));

        }

        // Handle success
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };
  const [qtrRespData, setQtrRespData] = useState<any>({});
  const [updateMessage, setUpdateMessage] = useState("");
  // Define an async function inside useEffect
  const fetchData = async () => {
    setFieldData({});
    setUpdateMessage("update");
    setNewData(false);
    if (date == null || date === "" || id == null || id === "") {
      toast.error("Please select a fund ID and a date");
      return;
    } else {
      try {
        const response = await axiosInstance.get(
          "/getFundQtr/" + date + "/" + id
        );
        if (response.data === "error") {
          toast.error("Error while fetching Data.");
        } else {
          if (response.data.data.length === 0) {
            toast.error("There is no data with the given Date");
            setFieldData({});
          } else {
            const responseData = response.data.data[0]; // Assuming this is your response data
            setQtrRespData(responseData);
            setFieldData(createFieldData(responseData));

          }

          // Handle success
        }
      } catch (err) {
        toast.error("Error while fetching Data.");
        console.error(err);
      }
    }
  };
  async function handleChangeData(
    formData: Record<string, string>,
    updateType: string
  ) {
    if (updateType !== "add" && updateType !== "update") {
      toast.error("Invalid update type");
      return;
    }
    try {
      let response;
      if (updateType === "add") {
        formData.fund_ID = id;
        response = await axiosInstance.post("/add_fundQtrNormal", { formData });
      } else {
        const updatedFormData = { ...formData };
        let uploadQtrRespData = { ...qtrRespData };
        Object.keys(updatedFormData).forEach((key) => {
          // eslint-disable-next-line eqeqeq
          if (key == "Reporting_Date" || key == "fund_ID" || key == "ReportingDateKey") return;
          uploadQtrRespData[key] = updatedFormData[key as keyof IFundQtr];
        });
        response = await axiosInstance.patch("/update_fundQtrNormal", {
          fund_ID: id,
          Reporting_Date: date,
          formData: uploadQtrRespData,
        });
      }

      if (response.data === "error") {
        toast.error("Error occurred while processing data");
      } else {
        toast.success(
          `Data successfully ${updateType === "add" ? "added" : "updated"}`
        );

        setFieldData({});
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while processing data");
    }
  }
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.button} onClick={fetchData}>
            Get previous data
          </button>
        </div>
        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.addbutton} onClick={fetchData2}>
            Add new Data
          </button>
        </div>
      </div>
      {Object.keys(fieldData).length !== 0 && (
        <>
          {updateMessage === "update" ? (
            <DynamicForm
              fieldData={fieldData}
              changedata={handleChangeData}
              staticData={true}
              newData={newData}
              reportingDate=""
            />
          ) : (
            <DynamicForm
              fieldData={fieldData}
              changedata={handleChangeData}
              staticData={false}
              newData={newData}
              reportingDate={date}
            />
          )}
        </>
      )}
    </div>
  );
}
export default FundQtr;
