import { parse } from "date-fns";

export const convertStringToDate = (dateStr: string): Date => {
  return parse(dateStr, "dd-MM-yyyy", new Date());
};
export const convertToDateFormat = (dateString:Date) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${day}-${month}-${year}`;
};
export const generateYears = (startYear: number): number[] => {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};
